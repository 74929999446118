import React, { Component } from "react"; 
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-date-picker';
import axios from 'axios';   
import { getApiHeaderGuest } from '../../utils/CommonFunctions'; 
import swal from 'sweetalert'; 
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";
import PublicSlider from './slider/PublicSlider'; 
import PageLoader from './../Loader/loader'; 
import { Link, withRouter} from "react-router-dom"; 

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   
const headers 		= getApiHeaderGuest();
class ForgotUserId extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            mobile_number: '' , 
            // dob: new Date(),
            dob: null, 
            error:false,
            message:null,
            otp_token :''
        };

        this.validator = new SimpleReactValidator();

    }

    changeUsername = (event) => {
        this.setState({mobile_number: event.target.value});
    }

    changeDob = (value) => 
    {
        this.setState({dob: value});
    }

     checkOtp = (event) => {
        this.setState({otp: event.target.value});
    }


    mySubmitHandler = async (event) => {

        event.preventDefault();

        this.setState({loading:true});

        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            this.setState({loading:false});

            return;

        }
        
        try
        {

            let dob = `${this.state.dob.getFullYear()}-${this.state.dob.getMonth()+1}-${this.state.dob.getDate()}`
            
            const post_data =   { mobile_number: this.state.mobile_number ,
                                dob: dob, 
                                };
            let res 		= await axios.post(`${ SERVER_URL }forgot-userid`,post_data, { headers });
            let data 		= res.data;  

            if(data.status==200){  
                // swal(data.message, "", "success");
                this.setState({error:false,message:""});
                this.setState({
                showMe:!this.state.showMe,
                otp_token:data.data.otp_token,
                otp_message: data.data.reqType === 'mob' ? "Please Enter OTP Received on your Registered Mobile Number." : "Please Enter OTP Received on your Registered Email."
                });
                 
            }else{
                swal(data.message, "", "warning");
            }

        }
        catch(error)
        {
            if(error.response && error.response.data && error.response.data.message)
            {
                this.setState({error:true,message:error.response.data.message});
            }
            else
            {
                this.setState({error:true,message:"Error"});
            }

        }



        


        this.setState({loading:false});
        
    }



    mySubmitHandlerotp = async (event) => { 
        event.preventDefault();  
        let do_b = `${this.state.dob.getFullYear()}-${this.state.dob.getMonth()+1}-${this.state.dob.getDate()}`
        const post_data =   { mobile_number: this.state.mobile_number ,
                              dob: do_b , 
                              otp: this.state.otp , 
                              otp_token:this.state.otp_token ,
                            };

                            console.log(post_data);
        
        await axios.post(`${ SERVER_URL }get-userid`,post_data, { headers }).then(response => {
            let data        = response.data; 
            if(data.status==200){ 
                swal(data.message+' : '+data.data, "", "success"); 
                this.setState({
                showMe:!this.state.showMe
                });
                this.props.history.push("/ForgotUserId");
            }else{
                swal(data.message, "", "warning");
            }
        }).catch(error => {
            swal(error.response.data.message, "", 'warning');
            
        });
    }

  
    render() { 

    
  return (
    <>
        {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-grid kt-grid--ver kt-grid--root common-layout">
        <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
        <GuestHeader/>
        <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid common-class">
            <section className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec">
                            <h2 className="text-center">Forgot Your User ID</h2>

                            {(this.state.error === true) ? <p style={{color:"#C11d6c", padding:"10px"}}>{this.state.message}</p> : null}

                            {
                            !this.state.showMe?
                            <form className="login-form"  onSubmit={this.mySubmitHandler}>
                                <p>To recover your User ID, enter your Telsim Mobile Number or NBN Service ID and Date of Birth.<br/> </p>
                                <div className="form-group">
                                    <label for="exampleInputMobile" className="text-uppercase">Enter Your Telsim Number</label>
                                    <input type="text" className="form-control" value={this.state.mobile_number} placeholder="" onChange={this.changeUsername} title="Please Enter Valid Mobile Number or NBN Service ID" maxLength="20"/>
                                    {this.validator.message('Telsim number', this.state.mobile_number, 'required', { className: 'text-danger' })}
                                </div> 
                                <div className="form-group">
                                    <label for="exampleInputDob" className="text-uppercase">Enter Date Of Birth</label>

                                    <div >
                                    <DatePicker
                                        onChange={this.changeDob}
                                        value={this.state.dob}
                                        maxDate= {new Date}
                                        className="form-control"
                                        format="y-MM-dd"
                                        
                                    />
                                    {this.validator.message('Date of birth', this.state.dob, 'required', { className: 'text-danger' })}
                                    </div>

                                </div> 
                                <div className="form-check" style={{textAlign:"center", paddingLeft:'0'}}> 
                                    <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto',marginTop:'0px'}}>Get User ID</button>
                                </div>
                            </form> 
                            :
                             <form className="login-form"  onSubmit={this.mySubmitHandlerotp}>
                                <div className="form-group">
                                    <input type="hidden" className="form-control" defaultValue="" value="" placeholder="" />
                                    <label for="exampleInputOtp" className="text-uppercase">Enter OTP</label>
                                    <input type="text" className="form-control" placeholder="" onChange={this.checkOtp} required/>
                                    <p>{this.state.otp_message}</p>
                                </div>  
                                <div className="form-check" style={{paddingLeft:'0'}}> 
                                    <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{marginLeft:'28%',marginTop:'0px'}}>Verfy Your OTP</button>
                                </div>
                            </form> 
                            }

                            <br/>
                            <div className="form-check" style={{textAlign:"center", paddingLeft:'0'}}>
                             <label className="form-check-label">
                                <Link to="/Login" className="kt-link kt-link--brand" >
                                    Back to Login
                                </Link>
                             </label> 
                            </div>

                        </div>
                        <div className="col-md-8 banner-sec">
                            <PublicSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
        
        <GuestFooter/>
        
        </div>
        </div>               
	</>
   )
}
}
export default withRouter(ForgotUserId);