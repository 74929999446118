import React, { Component } from "react";  
import { withRouter } from 'react-router-dom'
import rechargeFriend from './../../assets/img/image.png';




class RechargeHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rechargePayment: true,
            rechargeVoucher: false
        }
    }

    componentDidMount() {
    }

    toContinuePressHandle = async () => {
        const {rechargePayment, rechargeVoucher} = this.state
        if(rechargePayment == true){
            this.props.history.push("/Recharge");
        }else if(rechargeVoucher == true){
            this.props.history.push("/RechargeWithVoucher");
        }
    }

    render() {
        const {rechargePayment, rechargeVoucher} = this.state;
        //window.sessionStorage.setItem("recharge", "28-day_plan");
        return (
            <>
                <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor recharge" id="kt_content"> 
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                                <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__head">
                                            <div className="row" style={{width:"100%"}}>
                                                <div className="col-md-6">
                                                    <div className="kt-portlet__head-label" style={{marginLeft: -15}}>
                                                        <h3 className="kt-portlet__head-title">Recharge</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb60" style={{marginTop: 15}}>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{marginTop: 20}}>
                                                {/* <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid"> */}
                                                    <div className="kt-portlet__head-label">
                                                        <h1 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>Recharge your Service</h1>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p style={{fontWeight: 'bold'}}>How would you like to recharge?</p>}
                                                        </label>
                                                    </div>
                                                
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}>
                                                        
                                                        <div className="col-md-6" style={{width: '100%'}}>
                                                            <label className="kt-option" onClick={() => {
                                                                this.setState({rechargePayment: true, rechargeVoucher: false})
                                                            }}>
                                                            <span className="kt-option__control">
                                                            <span className="kt-radio kt-radio--check-bold">
                                                            <input type="radio" name="your_mobile_number" value="new" id="recharge_with_payment" checked={rechargePayment}/>
                                                            <span></span>
                                                            </span>
                                                            </span>
                                                            <span className="kt-option__label">
                                                            <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                            Recharge with existing payment method
                                                            </span>
                                                            </span>
                                                            </span>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6" style={{width: '100%'}}>
                                                            <label className="kt-option" onClick={() => {
                                                                this.setState({rechargePayment: false, rechargeVoucher: true})
                                                            }}>
                                                            <span className="kt-option__control">
                                                            <span className="kt-radio kt-radio--check-bold">
                                                            <input type="radio" name="your_mobile_number" value="new" checked={rechargeVoucher} id="recharge_with_voucher"/>
                                                            <span></span>
                                                            </span>
                                                            </span>
                                                            <span className="kt-option__label">
                                                            <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                            Recharge with a voucher
                                                            </span>
                                                            </span>
                                                            </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{marginTop: 15, width: '100%'}}>
                                                        <div className="kt-form__actions">
                                                            <button
                                                            type="submit"
                                                            style={{width: '100%', borderRadius: 5, height: 45}}
                                                            className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                            id="wizard_next_btn"
                                                            data-ktwizard-type="action-next"
                                                            onClick={this.toContinuePressHandle}
                                                            >
                                                            Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 hideView">
                                                <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid">
                                                    <div className="kt-portlet__body kt-portlet__body--fit">
                                                        <img src={rechargeFriend} alt="image"/>	
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </>
        )
    }
}


export default withRouter(RechargeHome);