import React, { Component } from "react";
import axios from "axios";
import { getApiHeader, removeUserSession} from "../../utils/CommonFunctions";
import swal from "@sweetalert/with-react";
import PageLoader from './../Loader/loader';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const isValidName = /^[a-zA-Z ]*$/;
const isValidPhNo = /^[0-9]*$/;

class ReferAbuddy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_activations: [],
      selected_number: null,
      buddyName: '',
      buddyEmail: '',
      buddyNumber: '',
      activation_id: 0,
      activation_status: '',
      isLoading: false,
    };
  }

  componentDidMount() {

    this.getUserStatus();
    this.getActivations();
  }



  // force logout --------------------------------------
  getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };


    handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    // this.props.history.push("/login");
                    window.location.href = "/login";
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------







  getActivations = async () => {
    this.setState({isLoading: true})
    let headers = await getApiHeader();
    let res = await axios.get(`${SERVER_URL}user_activations`, { headers });
    let data = res.data.data.list;
    let selected_number = '';
    let activation_id = 0;
    let activation_status = '';
    var numberList = [];
    if(data.length > 0){
      //numberList = data.filter(item => item.activation_status == '1')
        selected_number = data[0].mobile_number;
        activation_id = data[0].activation_id;
        activation_status = data[0].activation_status;
      if(data[0].activation_status == '1'){
        selected_number = data[0].mobile_number;
        activation_id = data[0].activation_id;
        activation_status = data[0].activation_status;
      } else if(data[0].activation_status == '9'){
        this.alertMessage('Mobile Number ' + data[0].mobile_number + ' has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
      } else if(data[0].activation_status == '5'){
        this.alertMessage('Please wait until your sim is activated to use your Telsim My Account. If you are facing any issues, please contact Telsim Customer Care.');
      } else {
        this.alertMessage('Mobile Number ' + data[0].mobile_number + ' is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
      }
    }else{ 
      selected_number = '';
      activation_id = 0;
    }
    this.setState({
      customer_activations: data, selected_number, activation_id, activation_status, isLoading: false
    }); 
  };

  handleChange =  (e) => {
    var active_number = e.target.value;
      var activation_id = e.target.selectedOptions[0].getAttribute('activation_id'); 
      var activation_status = e.target.selectedOptions[0].getAttribute('activation_status');
     
    if(e.target.selectedOptions[0].getAttribute('activation_status') == '1'){
      var active_number = e.target.value;
      var activation_id = e.target.selectedOptions[0].getAttribute('activation_id'); 
      var activation_status = e.target.selectedOptions[0].getAttribute('activation_status');
      this.setState({
          selected_number: active_number, activation_id, activation_status
      }); 
    } else if(e.target.selectedOptions[0].getAttribute('activation_status') == '9'){
      this.alertMessage('Mobile Number ' + e.target.value + ' has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
    } else if(e.target.selectedOptions[0].getAttribute('activation_status') == '5'){
      this.alertMessage('Please wait until your sim is activated to use your Telsim My Account. If you are facing any issues, please contact Telsim Customer Care.');
    } else {
      this.alertMessage('Mobile Number ' + e.target.value + ' is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
    }
    this.setState({
      selected_number: active_number, activation_id, activation_status
  }); 
    
  };

  onBuddyNameEditHandle = (name) => {
    //let bName = name.target.value;

    if(name.target.value.match(isValidName) && (name.target.value.length <= 45)){
      this.setState({buddyName: name.target.value});
    }    
  }

  onBuddyEmailEditHandle = (email) => {
    this.setState({buddyEmail: email.target.value});
  }

  onBuddyNoEditHandle = (phno) => {
    if((phno.target.value.length <= 10) && phno.target.value.match(isValidPhNo)){
      this.setState({buddyNumber: phno.target.value});
    }
  }

  alertMessage = (msg) => {
    swal(msg, "", "warning");
  }

  validateForm = () => {
    const {buddyEmail, buddyName, selected_number, activation_status} = this.state
    /* const isValidEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; */
    const isValidEmail = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    
    if(selected_number == ''){
      return "Select your number";
    } else if(activation_status == '9'){
      return "Mobile Number " + selected_number + " has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.";
    } else if(activation_status == '5'){
      return "Please wait until your sim is activated to use your Telsim My Account. If you are facing any issues, please contact Telsim Customer Care.";
    } else if(activation_status != '1'){
      return "Mobile Number " + selected_number + " is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.";
    } else if(buddyName == ''){
      return "Enter buddy name";
    } else if(buddyEmail == ''){
      return "Enter buddy email";
    }else if(!buddyEmail.match(isValidEmail)){
      return "Enter a valid email address";
    }
    return undefined
  }

  toRefferABuddy = async () => {
    const {buddyEmail, buddyName, selected_number, buddyNumber, activation_id} = this.state
    const error = this.validateForm();
    if(error) return this.alertMessage(error);
    this.setState({ isLoading: true });
    const post_data = { 
     referee_activation_id: activation_id,
      referee_mobile: selected_number,
      buddy_name: buddyName,
      buddy_email: buddyEmail,
      buddy_mobile: buddyNumber == '' ? "" : buddyNumber,
    };
    let headers     = await getApiHeader();
    let res = await axios
      .post(`${SERVER_URL}refer-a-buddy/send-buddy-code`, post_data, { headers })
      .then((response) => {
        this.setState({ isLoading: false });
        swal({
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false, 
          icon: "success"}).then(()=>{
  
            window.location.href = "/ReferAbuddy";
  
          });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (
          typeof error.response.data.message !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          this.alertMessage(error.response.data.message);
        } else {
          this.alertMessage("Internal Server Error");
        }
        
      });
  }

  render() {
    const {buddyEmail, buddyName, buddyNumber, isLoading, selected_number, customer_activations} = this.state;
    return (
      <>
      {(isLoading === true) && (<PageLoader/>)}
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class"
        id="kt_content"
      >
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
              <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="kt-portlet">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Refer A buddy</h3>
                    </div>                      
                  </div>
                  <div className="kt-portlet__body">
                    <div className="kt-section kt-section--first">
                      <div className="kt-section__body">
                        <div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                              Please select your number
                              </label>
                              <select className="form-select custom-select kt_selectpicker refer-select" 
                              defaultValue={selected_number} 
                              onChange={this.handleChange} 
                              name="activation">
                                { customer_activations.map(person => <option className="m--bg-success kt-font-inverse-success" activation_id={person.activation_id} activation_status={person.activation_status} key={person.activation_id} value={person.mobile_number}>{person.mobile_number}</option>)} 
                              </select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                {<p>Enter Buddy Name<span style={{color: '#C11D6C'}}>*</span></p>}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={buddyName}
                                onChange={this.onBuddyNameEditHandle}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                              {<p>Enter Buddy Email<span style={{color: '#C11D6C'}}>*</span></p>}
                              </label>
                              <input
                                className="form-control"
                                type='email'
                                value={buddyEmail}
                                onChange={this.onBuddyEmailEditHandle}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                Enter Buddy Mobile Number (Optional)
                              </label>
                              <input
                                className="form-control"
                                type='number'
                                maxLength={10}
                                max={10}
                                value={buddyNumber}
                                onChange={this.onBuddyNoEditHandle}
                              />
                            </div>
                          </div>
                          
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="kt-form__actions">
                                <button
                                  type="submit"
                                  className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                  id="wizard_next_btn"
                                  data-ktwizard-type="action-next"
                                  onClick={this.toRefferABuddy}
                                >
                                  Send
                                </button>
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default ReferAbuddy;
