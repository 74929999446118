import React, { Component } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import {getApiHeaderGuest ,getApiHeader, removeUserSession, getActivationAttemptDataYourMobile, getActivationInactivity} from '../../../../utils/CommonFunctions';
import swal from 'sweetalert';
import DatePicker from 'react-date-picker';
import PageLoader from './../../../Loader/loader';
import ErrorMsg from './../../../public/errorMsg';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  


export class YourMobile extends Component {

    constructor(props) {

        super(props); 
        
        this.validator = new SimpleReactValidator(); 

        this.state = {
            new_number_show: true, 
            keep_my_num_show: false,
			new_number_checked: true, 
			keep_my_num_checked: false, 
            existing_number: "", 
            otp_message_success: "", 
            otp_message_error: "", 
            otp_token: "",
            enter_otp_div:false,
            otp_send_button_text:"Request OTP",
            otp:"",
            section_port_div:false,
            providers_list:[],
            mobile_number_list:[],
            new_number: "",
            mobile_selection_type:"new",
            mobile_provider:"",
            port_account_number:"",
            port_dob:"",
            port_service_type:"",
            port_other_provider:"",
            other_provider_div:false,
            port_prepaid_div:false,
            port_postpaid_div:false,
            otp_verify_status:false,
            reservationId : "",
            loading:false,
            sim_activation_type:"both",
            new_type:"block",
            port_type:"block",
            simMessage:"Choose if you'd like to get a new number, or port your number from your current provider",
            maxdob:new Date(new Date().setFullYear(new Date().getFullYear()-16)),
            mindob:new Date(new Date().setFullYear(new Date().getFullYear()-110)),
            count: 0
        }


    }
    ///////////////Type hide and show //////////////////
    clickNewNumberShow =  (event) => {  

		this.setState({
			new_number_show: true, 
			keep_my_num_show: false,
            section_port_div:false,
			new_number_checked: true, 
			keep_my_num_checked: false, 
			mobile_selection_type: "new",
            // mobile_selection_type: event.target.value,
            existing_number:"",
            otp:"",
            otp_verify_status:false,
            otp_message_success:"",
            otp_message_error:"",
            enter_otp_div:false

		});    
		this.getMobileNumbers();
	};
    clickKeepMyNumberShow =  (event) => {  
		this.setState({
			new_number_show: false, 
			keep_my_num_show: true,
			new_number_checked: false, 
			keep_my_num_checked: true, 
			mobile_selection_type: "port"
            // mobile_selection_type: event.target.value
		});    
		this.getProviderList();
	};
    ////////////////////////////////////////////////////////////
    componentDidMount() {
		this.checkActivationInactivity();
        this.getMobileNumbers();
        this.getActivationAttemptData(); 
    }


    checkActivationInactivity = async () => {
        let inactiveData     = await getActivationInactivity(); 
        if(inactiveData.expired != 'undefined'){
            if(inactiveData.expired == 1){
                swal("Your session expired, Please try again", "", 'error');
                setTimeout(function() {
                      window.location.reload();
                    }, 2000);
            }else{
                if(inactiveData.inactiveMaxTime != 'undefined'){
                    if(inactiveData.inactiveMaxTime > 0){
                        let maxInactiveTime = inactiveData.inactiveMaxTime;
                        // repeat check inactive api here after maxInactiveTime millisecond
                        setTimeout(() => this.checkActivationInactivity(), maxInactiveTime);
                    }
                }

            }
        }        
    };


 
    getActivationAttemptData = async () => {
        this.setState({loading:true});
        let attempt_data     = await getActivationAttemptDataYourMobile();  
        if(attempt_data.activation_attempt !=null)
        {
            this.setState({ new_number: attempt_data.activation_attempt.mobile_number});  
            this.setState({ existing_number: attempt_data.activation_attempt.mobile_number});  
            // this.setState({ otp: attempt_data.activation_attempt.port_otp});
            this.setState({ otp: ""});   
            this.setState({ port_account_number: attempt_data.activation_attempt.port_account_id}); 

            //alert(attempt_data.activation_attempt.port_dob);
            if(attempt_data.activation_attempt.port_dob !=null)
            {
            this.setState({ port_dob: new Date(attempt_data.activation_attempt.port_dob)});  
            }

            this.setState({ port_service_type: attempt_data.activation_attempt.port_service_type}); 
            if(this.state.port_service_type=="Pre-paid"){  
            this.setState({ port_prepaid_div: true}); 
            this.setState({ port_postpaid_div: false}); 
            }else if(this.state.port_service_type=="Post-paid"){ 
            this.setState({ port_prepaid_div: true}); 
            this.setState({ port_postpaid_div: false}); 
            }
            else
            {}



            this.setState({ port_other_provider: attempt_data.activation_attempt.mobile_provider});
        
            this.setState({ mobile_provider: attempt_data.activation_attempt.mobile_provider}); 


            // sim type 25-03-2022 - set state above.
            this.setState({ sim_activation_type: attempt_data.simtype});
            if(this.state.sim_activation_type=="new")
            { this.clickNewNumberShow(); 
              this.state.port_type = 'none'; 
              this.state.simMessage = 'Get a new number for your Telsim Service'; 
              this.getMobileNumbers(); 
            }
            else if(this.state.sim_activation_type=="port_in")
            { this.clickKeepMyNumberShow(); 
              this.state.new_type = 'none'; 
              this.state.simMessage = 'Port your number from current provider'; 
            }
            else{ this.getMobileNumbers(); }
            // sim type 25-03-2022



        }
        this.setState({loading:false});
    };

    ////////////////Get Mobile Numbers API Call////////////////////
    getMobileNumbers = async () => {
        this.setState({loading:true}); 
        const API_TOKEN     = process.env.REACT_APP_API_TOKEN;
        const headers       =   { 
                                'api-token': API_TOKEN ,
                                'session-id':sessionStorage.getItem('activation_session_id') ,
                                'process-type' :"Activation"};


        let response 		= await axios.get(`${ SERVER_URL }activation/fetch-numbers`, { headers }).then(res => {

            let selected_number = "";
            let reservationId = "";
            if(res.data.data){ 
                let data 		= res.data.data.numbers;  
                selected_number = data.mobile_number;
                reservationId   = data.reservationId;

                if(data.sessionExpired != 'undefined'){
                    if(data.sessionExpired == true){
                        var fetchMsg = "Session expired, Please try again";
                        if(res.data.message){
                            fetchMsg = res.data.message;
                        }
                        swal(fetchMsg, "", 'error');
                        this.props.prevStep();
                    }
                }

            }else{
                selected_number = '';
                reservationId = '';
            }
    
            this.setState({ new_number: selected_number });  
            this.setState({ reservationId: reservationId });  
            this.setState({loading:false});

        }).catch(err => {
            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "waring"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        // swal(errorMessage,"error");
                        swal({
                            title: err.response.data.message,
                            icon: "error"});
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            
        });
        
	};
    //////////////Get Providers API Call //////////////////////////
    getProviderList = async () => {
		let headers 	= await getApiHeader();
		let res 		= await axios.get(`${ SERVER_URL }activationv2/fetch-mobile-providers`, { headers });
		let data 		= res.data.data.providers;  
		 
		this.setState({ providers_list: data });   
	};
    ///////////////////OTP Send API Call ///////////////////////
    handleOtpSend =  async(e) => {

        const re = /^[0-9\b]+$/;
        if(this.state.existing_number.toString().length == 10 && re.test(this.state.existing_number))
        {

            let headers 	= await getApiHeader();  
            const post_data = { 
                                session_id: sessionStorage.getItem('activation_session_id'),  mobile_number: this.state.existing_number 
                                };
            try{
                
                let res 		= await axios.post(`${ SERVER_URL }activationv2/port-request-otp`,post_data, { headers });
                let data 		= res.data; 

                if(data.data.takefull_amount ==1)
                {
                    this.winbackVerify(data);
                }
                else
                {
                this.setState({ otp_message_success: data.message });
                this.setState({ otp_message_error: "" });
                this.setState({ otp_token: data.data.otp_token }); 
                this.setState({ enter_otp_div: true }); 
                this.setState({ otp_send_button_text: "Resend OTP" }); 
                }
            } catch(err)
            {   
                this.setState({ otp_message_error: err.response.data.message});
                this.setState({ otp_message_success: "" });
                this.setState({ enter_otp_div: false }); 
            }
        }
        else
        {
            swal({title: "Enter Valid Mobile Number",
                                icon: "error"});
        }
		
	};



    //winback full amount yes or no
        winbackVerify = (data)=>{
          

          //alert(data);

         swal({
                    buttons: {
                    cancel: true,
                    confirm: {text: 'Yes'},
                    },
                    title: "",
                    text: data.data.port_in_check_message,
                    dangerMode: true,
                    })
                    .then(willDelete => {

                        if (willDelete) {
                            this.setState({ otp_message_success: data.message });
                            this.setState({ otp_message_error: "" });
                            this.setState({ otp_token: data.data.otp_token }); 
                            this.setState({ enter_otp_div: true }); 
                            this.setState({ otp_send_button_text: "Resend OTP" }); 
                        }
                        else
                        {
                            // no action
                        }

                    });




    };





    /////////////////////OTP Verify API Call ////////////////////
    handleOtpVerify =  async(e) => { 
		let headers 	= await getApiHeader();   
		const post_data_otp = { 
                            mobile_number: this.state.existing_number,
                            otp: this.state.otp,
                            session_id: this.state.otp_token,
                            act_session_id: sessionStorage.getItem('activation_session_id')
                          };   

        try
        {

            let res 		= await axios.post(`${ SERVER_URL }activationv2/port-otp-verify`,post_data_otp, { headers });
            let data 		= res.data; 
            // console.log(data);
            this.setState({ otp_message_success: data.message }); 
            this.setState({ section_port_div: true }); 
            this.setState({ otp_verify_status: true });  

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "waring"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        // swal(errorMessage,"error");
                        swal({
                            title: err.response.data.message,
                            icon: "error"});
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

	};
    ///////////////////////Form Data Set //////////////// 
    getExistingNumber = (event) => { 
        this.setState({ existing_number: event.target.value}); 
    }
    getPortOtp = (event) => { 
        this.setState({ otp: event.target.value}); 
    }
    getNewNumber = (event) => { 
        this.setState({ new_number: event.target.value}); 
    }
    clickNewNumber =  (event) => {  
        this.setState({ new_number: event.target.value });  
	};
    getProvider =  (event) => {  
        let mobile_provider = event.target.value;
        this.setState({ mobile_provider: mobile_provider});  
        if(mobile_provider=="Other"){  
            this.setState({ other_provider_div: true}); 
        }else{ 
            this.setState({ other_provider_div: false}); 
        }
	};
    getOtherProvider =  (event) => {  
        this.setState({ port_other_provider: event.target.value });  
	};
    getPortServiceType =  (event) => {   
        let port_service_type = event.target.value;
        this.setState({ port_service_type: port_service_type});  
        if(port_service_type=="Pre-paid"){  
            this.setState({ port_prepaid_div: true}); 
            this.setState({ port_postpaid_div: false}); 
        }else{ 
            this.setState({ port_prepaid_div: false}); 
            this.setState({ port_postpaid_div: true}); 
        }
	};
    getPortDob =  (value) => {  
        this.setState({ port_dob: value});  
       // alert(this.state.port_dob);
	};
    getPortAccountNumber =  (event) => {  
        this.setState({ port_account_number: event.target.value });  
	};
    ////////////////////////Form Submit ////////////////////
    mySubmitHandler = async () => { 

       // event.preventDefault();  

        

        if(this.state.mobile_selection_type === 'port')
        {

            let validate_error = false;

            if(this.validator.fieldValid('Mobile provider') === false) 
            {
                validate_error = true;
            }

            if((this.state.other_provider_div) && (this.validator.fieldValid('Other mobile provider') === false))
            {
                validate_error = true;
            }

            if(this.validator.fieldValid('Type of Service') === false) 
            {
                validate_error = true;
            }

            if((this.state.port_service_type === 'Pre-paid') && (this.validator.fieldValid('Date of Birth') === false))
            {
                validate_error = true;
            }

            if((this.state.port_service_type === 'Post-paid') && (this.validator.fieldValid('Account Number') === false))
            {
                validate_error = true;
            }


            if(validate_error === true)
            {

                this.setState({loading:false});
                this.validator.showMessages();
                this.forceUpdate();
                return;

            }

        }



        var actNumber  = this.state.new_number;
        if((actNumber == null || actNumber == '') && this.state.mobile_selection_type != 'port'){

            this.setState({loading:false});
            swal("Invalid Mobile Number", "", "warning");

        }else{

            // if(this.validator.allValid() == false) 
            // {

            //     alert("validator");

            //     this.setState({loading:false});

            //     this.validator.showMessages();

            //     this.forceUpdate();

            //     return;

            // }

            //let port_dob    = `${this.state.port_dob.getDate()}/${this.state.port_dob.getMonth()+1}/${this.state.port_dob.getFullYear()}`;
            //this.setState({loading:true});
            const post_data =   {   session_id: sessionStorage.getItem('activation_session_id') ,
                                    mobile_selection_type: this.state.mobile_selection_type , 
                                    select_number: this.state.new_number , 
                                    existing_number: this.state.existing_number , 
                                    mobile_provider: this.state.mobile_provider, 
                                    other_provider: this.state.port_other_provider, 
                                    port_service_type:this.state.port_service_type, 
                                    port_dob: this.state.port_dob,
                                    port_acc_id: this.state.port_account_number, 
                                    reservationId:this.state.reservationId 
                                };  
            let headers     = await getApiHeader();
            try{
                let res 		= await axios.post(`${ SERVER_URL }activationv2/select-your-number`,post_data, { headers });
                let data 		= res.data;  
                if(data.status == 200){ 
                    this.props.goNext(4);
                    this.setState({loading:false});
                }else{
                    this.setState({loading:false});
                    swal(data.message, "", "warning");
                }
            }catch(err){
                this.setState({loading:false});

                if(err)
                {
                    let errorMessage = '';
    
                    if(err.response && err.response.data && err.response.data.message)
                    {
                        if(typeof(err.response.data.message) === 'object')
                        {
    
                            swal({
                                title: "Error",
                                content: <ErrorMsg message={err.response.data.message}/>,
                                icon: "waring"});
            
                        }
                        else
                        {
                            errorMessage = err.response.data.message;
                            // swal(errorMessage,"error");
                            swal({
                                title: err.response.data.message,
                                icon: "error"});
                            
                        }
                    }
                    else
                    {
                        errorMessage = err;
                        swal(errorMessage,err);
                    }
        
    
    
                }
            }
             

        }
        
    };

    back = async (e) => {
        
        e.preventDefault();

        this.props.prevStep();
        

    };
    // onSubmit={(e)=>{
    //     this.setState({loading: true})
    //     this.mySubmitHandler(e)
    //     }}

    render() {
        const { values, handleChange } = this.props;
        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
            <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post">
                <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                    <div className="kt-heading kt-heading--md">Your Mobile Number</div>
                    <div className="kt-form__section kt-form__section--first">
                        <div className="form-group form-group-marginless"> 

                            <label>{this.state.simMessage}</label>

                            <div className="row">
                                <div className="col-lg-6" style={{display:(this.state.new_type)}}>
                                    <label className="kt-option">
                                        <span className="kt-option__control">
                                            <span className="kt-radio kt-radio--check-bold">
                                                <input type="radio" name="your_mobile_number" value="new" id="your_mobile_number_new" onClick={this.clickNewNumberShow}  checked={this.state.new_number_checked? 'checked':''} />
                                                <span></span>
                                            </span>
                                        </span>
                                        <span className="kt-option__label">
                                            <span className="kt-option__head">
                                                <span className="kt-option__title">
                                                    Get New Number
                                                </span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <div className="col-lg-6" style={{display:(this.state.port_type)}}>
                                    <label className="kt-option">
                                        <span className="kt-option__control">
                                            <span className="kt-radio kt-radio--check-bold">
                                                <input type="radio" name="your_mobile_number" value="port" id="your_mobile_number_port" onClick={this.clickKeepMyNumberShow} checked={this.state.keep_my_num_checked? 'checked':''}/>
                                                <span></span>
                                            </span>
                                        </span>
                                        <span className="kt-option__label">
                                            <span className="kt-option__head">
                                                <span className="kt-option__title">
                                                    Keep My Number (Port Your Existing Number)
                                                </span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div id="new_number_div" style={{display:(this.state.new_number_show? 'block':'none')}}>
                            <div className="form-group row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="">Your Telsim Number</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="" aria-describedby="basic-addon1" name="assigned_telsim_number" id="assigned_telsim_number" readOnly onChange={this.getNewNumber} defaultValue={this.state.new_number}/>
                                        <span id="assigned_telsim_number_error" style={{color: '#C11D6C'}} role="alert"></span>
                                    </div>
                                </div>

                            </div> 
                            {/* <div className="form-group row">
                                { this.state.mobile_number_list.map((mobile_number,i) => 
                                <div class="col-lg-6" style={{marginTop: '10px'}}>
                                    <label class="kt-option">
                                        <span class="kt-option__control">
                                            <span class="kt-radio kt-radio--check-bold">
                                                <input type="radio" name="select_number" value={mobile_number.mobile_number} id="select_number_0" onClick={this.clickNewNumber}  />
                                                <input type="hidden" name="select_number_amount" value="0" />
                                                <span></span>
                                            </span>
                                        </span>
                                        <span class="kt-option__label">
                                            <span class="kt-option__head">
                                                <span class="kt-option__title">
                                                {mobile_number.mobile_number}
                                                </span> 
                                                <span class="kt-option__focus"> FREE </span>
                                            </span>
                                        </span>
                                    </label>
                                </div> 
                                )}
                            </div> */}
                            
                        </div>
                        <div className="form-group row keep_my_num_div" style={{display:(this.state.keep_my_num_show? 'block':'none')}}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="">Enter Your Existing Mobile Number</label>
                                    <div className="input-group">

                                        <input type="text" className="form-control" maxLength="10" placeholder="Enter Existing Number" aria-describedby="basic-addon1" name="existing_number" id="existing_number" onKeyPress="return isNumberKey(event);" style={{width: '70%'}} onChange={this.getExistingNumber} value={this.state.existing_number} />
                                        {this.state.otp_verify_status ==false && 
                                        <div className="input-group-append">
                                            <button className="btn btn-brand" id="request_port_otp" type="button" style={{height: '40px'}} onClick={this.handleOtpSend}>{this.state.otp_send_button_text}</button> 
                                        </div>
                                        }
                                            <span  style={{color: 'green'}} role="alert">{this.state.otp_message_success}</span>
                                            <span  style={{color: '#C11D6C'}} role="alert">{this.state.otp_message_error}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 verify_port_otp_div"  style={{display:(this.state.enter_otp_div? 'block':'none')}}>
                                    <label className="">Enter OTP</label>
                                    <div className="input-group">

                                        <input type="text" className="form-control" placeholder="Enter OTP" aria-describedby="basic-addon1" name="port_otp" id="port_otp" style={{width: '70%'}} onChange={this.getPortOtp} value={this.state.otp}/>
                                        {this.state.otp_verify_status ==false && 
                                        <div className="input-group-append">
                                            <button className="btn btn-brand" id="verify_port_otp" type="button" style={{height: '40px'}} onClick={this.handleOtpVerify} >Verify OTP</button>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="existing_number_error" style={{color: '#C11D6C'}}></span>
                                <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="existing_number_success" style={{color: 'green'}}></span>
                            </div>
                        </div>
                        <div className="form-group row section_port" style={{display:(this.state.section_port_div? 'block':'none')}}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="">Select Mobile Provider</label>
                                    <select className="form-control " name="mobile_provider" id="mobile_provider" onChange={this.getProvider} value={this.state.mobile_provider}>
                                        <option value="">Select Operator</option> 
                                        { this.state.providers_list.map(provider => <option value={provider.provider}>{provider.provider}</option>)} 
                                    </select>
                                    {this.validator.message('Mobile provider', this.state.mobile_provider, 'required', { className: 'text-danger' })}
                                    <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="mobile_operator_error" style={{color: '#C11D6C'}}></span>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 enter_provider_txt" style={{display:(this.state.other_provider_div? 'block':'none')}}>
                                    <label>Enter Mobile Provider Name</label> 
                                    <input type="text" className="form-control" placeholder="Enter Mobile Provider Name" aria-describedby="basic-addon1" name="other_provider" id="other_provider" onChange={this.getOtherProvider} defaultValue={this.state.port_other_provider} /> 
                                    {/* <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="other_provider_error" style={{color: '#C11D6C'}}> </span> */}
                                    {(this.state.other_provider_div) ?
                                    this.validator.message('Other mobile provider', this.state.port_other_provider, 'required', { className: 'text-danger' })
                                    :
                                    null}
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="">Type of Service</label>
                                    <select className="form-control kt_selectpicker" name="port_service_type" id="port_service_type" onChange={this.getPortServiceType} value={this.state.port_service_type} >
                                        <option value="">Select Type of Service</option>
                                        <option value="Pre-paid">Pre-paid</option>
                                        <option value="Post-paid">Post-paid</option>
                                    </select>
                                    {/* <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_service_type_error" style={{color: '#C11D6C'}}></span> */}
                                    {this.validator.message('Type of Service', this.state.port_service_type, 'required', { className: 'text-danger' })}
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 port_prepaid_div" style={{display:(this.state.port_prepaid_div? 'block':'none')}}>

                                    <label>Enter Date of Birth</label>
 
                                    <DatePicker
                                        onChange={this.getPortDob}
                                        value={this.state.port_dob}
                                        className="form-control rmvdftstyle"
                                        format="y-MM-dd" 
                                        defaultValue={this.state.maxdob}
                                        minDate={this.state.mindob}
                                        maxDate={this.state.maxdob}
                                        onKeyDown={(e) => {
                                        e.preventDefault();
                                        }}
                                          
                                    />

                                    {(this.state.port_service_type === 'Pre-paid') ? this.validator.message('Date of Birth', this.state.port_dob, 'required', { className: 'text-danger' }) : null}

                                    {/* <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_dob-error" style={{color: '#C11D6C'}}></span> */}
                                </div>


                                <div className="col-lg-6 col-md-6 col-sm-12 port_postpaid_div" style={{display:(this.state.port_postpaid_div? 'block':'none')}}>
                                    <label>Enter Account Number</label>  
                                    <input type="text" className="form-control" placeholder="Enter Account Number" aria-describedby="basic-addon1" name="port_acc_id" id="port_acc_id" onChange={this.getPortAccountNumber} defaultValue={this.state.port_account_number} /> 
                                    
                                    {(this.state.port_service_type === 'Post-paid') ? this.validator.message('Account Number', this.state.port_account_number, 'required', { className: 'text-danger' }) : null}
                                    {/* <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_acc_id_error" style={{color: '#C11D6C'}}> </span> */}
                                </div>

                                
                            </div>
                        </div>
                    </div>
                                        

                   

                    
                    <br/>
                    <div className="kt-form__actions">
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp;
                       <button type="button" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" onClick={()=> {
                        this.setState({loading: true})
                        this.mySubmitHandler()
                       }} disabled={this.state.loading}>Next Step</button>
                    </div>

                </div>      
            </form>  
            </> 
        )
    }
}

export default YourMobile
