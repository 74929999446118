import React, { Component } from 'react';
import PlanTabs from './../../../public/plans/planTabs';
import ErrorMsg from './../../../public/errorMsg';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import Plan from './../plans/plan';
import {getApiHeaderGuest ,getApiHeader, removeUserSession, getActivationInactivity} from './../../../../utils/CommonFunctions';
import PageLoader from './../../../Loader/loader';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;
const isValidNo = /^[0-9]*$/;

export class YourPlan extends Component {


    constructor(props)
    {
        super(props);

        this.state = {
            loading:false,
            seletedPlanId:null,
            reference_number: '',
            reference_number_applied:false,
            reference_number_plan:null,
            is_sim_credit_plan:false
        }

    }

    componentDidMount = ()=>
    {
        this.checkActivationInactivity(); 
        this.setState({loading:false});
    }


    checkActivationInactivity = async () => {
        let inactiveData     = await getActivationInactivity(); 
        if(inactiveData.expired != 'undefined'){
            if(inactiveData.expired == 1){
                swal("Your session expired, Please try again", "", 'error');
                setTimeout(function() {
                      window.location.reload();
                    }, 2000);
            }else{
                if(inactiveData.inactiveMaxTime != 'undefined'){
                    if(inactiveData.inactiveMaxTime > 0){
                        let maxInactiveTime = inactiveData.inactiveMaxTime;
                        // repeat check inactive api here after maxInactiveTime millisecond
                        setTimeout(() => this.checkActivationInactivity(), maxInactiveTime);
                    }
                }

            }
        }        
    };


    mySubmitHandler = async (event) => { 
        event.preventDefault();  
    };

    back = (e) => {

        e.preventDefault();

        this.props.prevStep()
        
    };

    goNextStep = async (plan_id)=>{
        //console.log(plan_id)
        //await sessionStorage.setItem('plan_id', plan_id);
        this.setState({seletedPlanId: plan_id});
        return;
        
    }

    setSelectedPlan = async (plan_id, reference_number_applied, plan, reference_number, is_sim_credit_plan)=>{
        //console.log(plan_id)
        this.setState({seletedPlanId: plan_id, reference_number_applied:reference_number_applied, reference_number_plan:plan, reference_number:reference_number, is_sim_credit_plan:is_sim_credit_plan},()=>{

            if(reference_number_applied === true)
            {
                this.setState({reference_number_plan:plan});
            }

        });

    }

    addPlan  = async ()=>{

             
            console.log('plan_id', sessionStorage.getItem('plan_id'))
            if(this.state.seletedPlanId === null)
            {

                this.setState({loading:false});

                swal({
                    title:"Please select a plan first.",
                    icon:"warning"
                });
                return;
            }
            this.setState({loading:true});

            let plan_id         = this.state.seletedPlanId;

            let headers 	    = await getApiHeader();

            let params          = {session_id: sessionStorage.getItem('activation_session_id'), plan_id: plan_id};
            console.log('response ==>', params);
        try 
        {

            let response 		= await axios.post(`${ SERVER_URL }activationv2/select-plan`, params, { headers });
            //console.log('response ==>', response);
            if(response.data.status==200){ 
                this.props.nextStep();
                this.setState({loading:false});
            }else{
                this.setState({loading:false});
                swal(response.data.message, "", "warning");
            }

            // swal({
            //         text: response.data.message,
            //         icon: "success"}).then(()=>{

            //             this.props.nextStep();

            // });

            //this.props.nextStep();
            
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }

    changeReferenceNumberInput = (e) => {
        e.preventDefault();
        if(e.target.value.match(isValidNo)){
            this.setState({reference_number: e.target.value});
        }else{
            console.log('false', e.target.value);
        }
        //this.setState({reference_number: e.target.value});
    }

    verifyReferenceNumber = async () =>{

        this.setState({reference_number_applied:false, loading:true});

        try 
        {

            let headers 	= await getApiHeader();

            let inputData   = {session_id: sessionStorage.getItem('activation_session_id'), 'reference_number': this.state.reference_number, sim_type: sessionStorage.getItem('simtype')};
            
            let res 		= await axios.post(`${ SERVER_URL }activationv2/verify-reference-number`, inputData, { headers });

            this.setState({loading:false});
            //console.log(res)

            if((res.data.data) && (res.data.data.plan.plan_id) && (res.data.data.plan) && ((res.data.data.plan.plan_id)))
            {
                this.setState({reference_number_applied:true, reference_number_plan:res.data.data.plan, seletedPlanId:res.data.data.plan.plan_id});
                //await sessionStorage.setItem('plan_id', Number(res.data.data.plan.plan_id));
                //await sessionStorage.setItem('plan_id', plan_id);
                // this.setState({seletedPlanId:plan_id});
            }

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;

                        swal({
                            title:errorMessage,
                            icon:"warning"
                        });
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal({
                        title:errorMessage,
                        icon:"warning"
                    });
                }
    


            }
            

        }

    }

    render() {
        const { values, handleChange } = this.props;
        return (
            <>
                {(this.state.loading === true) ? <PageLoader/> : null}

                <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.mySubmitHandler}>
                    <div className="kt-wizard-v2__content " data-ktwizard-type="step-content">
                    
                        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                            <div className="kt-heading kt-heading--md">Your Plan</div>
                            {(this.state.is_sim_credit_plan === false) ? 
                            <>
                                <label style={{border:'1px solid',borderColor: '#C11D6C',borderRadius: '10px',padding: '2px',margin: '2px'}} className="ref_div"><b><i>Note: If you have already purchased the Telsim plan online or you have a promo code, enter your purchase reference number or promo code here. Purchase Reference Number will be mentioned on the email confirmation sent to you or in the invoice.</i></b></label>
                                <div className="kt-separator kt-separator--height-xs"></div>
                                <div className="kt-form__section kt-form__section--first">
                                    <div className="form-group row ref_div">
                                        <label className="col-lg-12 col-md-12 col-sm-12">Enter Reference Number or Promo Code</label>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="input-group"> 
                                                <input readOnly={this.state.reference_number_applied} onChange={this.changeReferenceNumberInput} value={this.state.reference_number} type="text" className="form-control" placeholder="Enter Reference Number" aria-describedby="basic-addon1" name="reference" id="reference" />
                                                <div className="input-group-append">
                                                    <button onClick={this.verifyReferenceNumber} className="btn btn-brand" id="verify_ref" type="button" style={{'height':'100%'}}>Apply</button>
                                                </div>
                                                {(this.state.reference_number_applied === true) ?
                                                    <span class="col-lg-12 col-md-12 col-sm-12" role="alert" id="ref_success" style={{"color": "green"}}>
                                                        Reference number applied.
                                                    </span>
                                                : null }
                                            </div>
                                        </div>
                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="ref_error" style={{color: '#C11D6C'}}></span>
                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="ref_success" style={{color: 'green'}}></span> 
                                    </div>      
                                </div>       
                            </>     
                           : null} 


                            {(this.state.reference_number_applied == false) ? 
                                <PlanTabs setSelectedPlan={this.setSelectedPlan} activeSection="28-day_plan" from="activation" goNextStep={this.goNextStep} /> 
                            : <Plan plan_reference_number={this.state.reference_number} referenceNumberApplied={true} plan={this.state.reference_number_plan} selectedPlan={this.state.reference_number_plan.plan_id}/>}

                                                
                            <div className="international_plans" style={{display: 'none'}}>
                                <div className="form-group row mt-1">

                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <select className="form-control kt_selectpicker" id="country" name="country" data-live-search="true">
                                                    <option value="">Search Country </option>
                                                    <option value="5">Afghanistan</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-6">

                                                <label name="selected_zone" className="form-control" id="selected_zone" style={{textAlign: 'center'}}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row form-group-marginless ">

                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <label className="kt-option">
                                                    <span className="kt-option__control">
                                                        <span className="kt-checkbox kt-checkbox--bold kt-checkbox--brand kt-checkbox--check-bold" checked>
                                                            <input type="checkbox" name="idd_zone[]" value="A" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Zone A&nbsp;
                                                                <span className="d-inline-block" tabindex="0" data-toggle="kt-tooltip" data-skin="brand" title="New Zealand,Tokelau,USA,United Kingdom,Canada,Hong Kong,Ireland (Republic),Singapore,Italy,San Marino,Vatican City,France,Germany,Greece,Japan,Monaco,Sweden,Switzerland,Denmark,Netherlands,Malaysia,Austria,Finland,Liechtenstein,Norway" data-placement="bottom">
                                                                    <button className="btn btn-icon" type="button"><i className="flaticon-questions-circular-button"></i></button>
                                                                </span>
                                                            </span>
                                                            <span className="kt-option__focus">
                                                                $2.00
                                                            </span>
                                                        </span>
                                                        <span className="kt-option__body">
                                                            For 100 Minutes
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="kt-option">
                                                    <span className="kt-option__control">
                                                        <span className="kt-checkbox kt-checkbox--bold kt-checkbox--brand kt-checkbox--check-bold">
                                                            <input type="checkbox" name="idd_zone[]" value="B" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Zone B&nbsp;
                                                                <span className="d-inline-block" tabindex="0" data-toggle="kt-tooltip" data-skin="brand" title="Antarctica,Norfolk Is,South Africa,Philippines,Spain,Andorra,Guam,Papua New Guinea,Puerto Rico,Slovak Republic,US Virgin Islands,Israel (&amp; Occ Terr),Korea South,Palestinian Autonomous Areas" data-placement="bottom">
                                                                    <button className="btn btn-icon" type="button"><i className="flaticon-questions-circular-button"></i></button>
                                                                </span>
                                                            </span>
                                                            <span className="kt-option__focus">
                                                                $3.00
                                                            </span>
                                                        </span>
                                                        <span className="kt-option__body">
                                                            For 100 Minutes
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="kt-option">
                                                    <span className="kt-option__control">
                                                        <span className="kt-checkbox kt-checkbox--bold kt-checkbox--brand kt-checkbox--check-bold">
                                                            <input type="checkbox" name="idd_zone[]" value="C" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Zone C&nbsp;
                                                                <span className="d-inline-block" tabindex="0" data-toggle="kt-tooltip" data-skin="brand" title="Belgium,Fiji,Niue Island,Sri Lanka,China,East Timor,Indonesia,Taiwan,Thailand,Bahamas,Chile,Cyprus,Cyprus (Turk Occ. Area),Czech Republic,Dominican Republic,Hungary,Luxembourg,Poland,Turkey,Kiribati,Malta,Nauru,Samoa (American),Samoa (Western),Solomon Islands,Tonga,Vanuatu,Brunei,French Polynesia,New Caledonia,Tuvalu,Venezuela,Croatia,Yugoslavia,Brazil,Mexico,Argentina,Bosnia Hertzegovina,Cuba,Dominica,Estonia,Iceland,Macedonia (F Y R),Netherlands Antilles,Portugal,Russia,Sakhalin Island,Slovenia,Trinidad &amp; Tobago,Ukraine,India,Lebanon,Comoros,Cook Islands,Faeroe Island,Greenland,Mayotte,United Arab Emirates,Vietnam" data-placement="bottom">
                                                                    <button className="btn btn-icon" type="button"><i className="flaticon-questions-circular-button"></i></button>
                                                                </span>
                                                            </span>
                                                            <span className="kt-option__focus">
                                                                $5.00
                                                            </span>
                                                        </span>
                                                        <span className="kt-option__body">
                                                            For 100 Minutes
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="kt-option">
                                                    <span className="kt-option__control">
                                                        <span className="kt-checkbox kt-checkbox--bold kt-checkbox--brand kt-checkbox--check-bold">
                                                            <input type="checkbox" name="idd_zone[]" value="D" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Zone D&nbsp;
                                                                <span className="d-inline-block" tabindex="0" data-toggle="kt-tooltip" data-skin="brand" title="Egypt,Oman,Antigua &amp; Barbuda,Aruba,Cayman Islands,Guadeloupe,Jamaica,Jordan,Latvia,Lesotho,Macau,Martinique,Mauritius,Nigeria,Panama,Peru,Romania,St Pierre &amp; Miquelon,Surinam,Tajikistan,Turkmenistan,Uruguay,Uzbekistan,Zimbabwe,Armenia,Azerbaijan,Bahrain,Bangladesh,Belarus,French Guiana,Georgia,Guyana,Kazakhstan,Kenya,Kuwait,Kyrgyzstan,Laos,Lithuania,Moldova,Nepal,Cambodia,Iran,Pakistan,Barbados,Belize,Bermuda,Bhutan,Bolivia,Botswana,Colombia,Ecuador,Falklands,Grenada,Guantanamo Bay,Guatemala,Iraq,Libya,Malawi,Mariana Islands / Saipan,Marshall Islands,Micronesia,Myanmar,Namibia,Nicaragua,Paraguay,Reunion,Rwanda,Saudi Arabia,St Helena,St Kitts &amp; Nevis,St Lucia,St Vincent &amp; Bequia,Sudan,Tanzania,Uganda,Wallis &amp; Futuna Islands,Yemen Arab Rep,Yemen PDR,Zambia,Albania,Angola,Ascension,Benin,British Virgin Islands,Bulgaria,Burkina Faso,Burundi,Cape Verde,Central African Republic,Congo,Costa Rica,Diego Garcia,El Salvador,Gabon,Gambia,Ghana,Gibraltar,Guinea Republic,Haiti,Ivory Coast,Korea North,Madagascar,Maldives Republic,Mali,Mauritania,Montserrat,Niger,Palau,Qatar,Sao Tome &amp; Principe,Seychelles,Sierra Leone,Swaziland,Syria,Togo,Turks &amp; Caicos Islands" data-placement="bottom">
                                                                    <button className="btn btn-icon" type="button"><i className="flaticon-questions-circular-button"></i></button>
                                                                </span>
                                                            </span>
                                                            <span className="kt-option__focus">
                                                                $10.00
                                                            </span>
                                                        </span>
                                                        <span className="kt-option__body">
                                                            For 100 Minutes
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="kt-option">
                                                    <span className="kt-option__control">
                                                        <span className="kt-checkbox kt-checkbox--bold kt-checkbox--brand kt-checkbox--check-bold">
                                                            <input type="checkbox" name="idd_zone[]" value="E" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Zone E&nbsp;
                                                                <span className="d-inline-block" tabindex="0" data-toggle="kt-tooltip" data-skin="brand" title="Afghanistan,Algeria,Anguilla,Cameroon,Chad,Djibouti,Equatorial Guinea,Eritrea,Ethiopia,Guinea-Bissau,Honduras,Liberia,Mongolia,Morocco,Mozambique,Senegal,Somalia,Tunisia,Zaire" data-placement="bottom">
                                                                    <button className="btn btn-icon " type="button"><i className="flaticon-questions-circular-button"></i></button>
                                                                </span>
                                                            </span>
                                                            <span className="kt-option__focus">
                                                                $15.00
                                                            </span>
                                                        </span>
                                                        <span className="kt-option__body">
                                                            For 100 Minutes
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        
                            <br/>
                            <div className="kt-form__actions">
                                <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp;
                                <button onClick={this.addPlan} type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading}>Next Step</button>
                            </div>

                        </div>  
                    </div>      
                </form>
            </>
        )
    }
}

export default YourPlan
