import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import maleUserImg from './../../../assets/media/users/computer-icons-user-profile-avatar.jpg';
import swal from '@sweetalert/with-react';
import axios from 'axios';
import PageLoader from './../../Loader/loader';
import Modal from 'react-bootstrap/Modal';
import { getApiHeader, removeUserSession } from '../../../utils/CommonFunctions';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import DropIn from 'braintree-web-drop-in-react';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;


class NbnAccountSummary extends Component {
  state = {
    loading: false,
    activate_status: "",
    expiry_date: "",
    product_name: '',
    plan_remaining_days: 0,
    model_visibility: false,
    has_nbn_default_card: false,
    addPaymentCard: false,
    account_summary: [],
    plan_details: [],
    clientToken: null,
    serviceId: '',
    customer_details: [],
    service_details: [],
    payNow: false,
    model_visibility: false,
    changeRequest: false,
    planName: "",
    planAmount: "",
    credit: "",
    gstPlanAmount: "",
    nbnStatus: "",
    is_term_and_condition_checked: false,
    disconnection_status: false,
    suspended_status: false,
    expiry: false,
    reactivation: false,
    add_service_acknowledgement : false,
    is_check_age_bar_checked:false,
    is_check_transfer_acknowledgement:false,
    is_service_qualification_check:false,
    is_check_holder_acknowledgement:false,
    is_service_validate_home_phone_check:false,
    validate_service_acknowledgement:false,
  }

  componentDidMount() {
    this.getAccountSummary();

  }

  Today = new Date()

  handleExpiry = () => {
    if (this.state.expiry_date < this.Today) {
      this.setState({ expiry: true })
    }
  }









  fetchClientToken = async () => {
    try {
      let headers = await getApiHeader();
      let res = await axios.get(`${SERVER_URL}payments/get-client-token`, { headers });
      let response = res.data.data;
      this.setState({ clientToken: response.clientToken });
    } catch (err) {
      if (err) {
        let errorMessage = '';

        if (err.response && err.response.data && err.response.data.message) {
          if (typeof (err.response.data.message) === 'object') {
            swal({
              title: "Error",
              content: "Error in add card",
              icon: "error"
            });
          } else {
            errorMessage = err.response.data.message;
            swal(errorMessage, "error");
          }
        } else {
          errorMessage = err;
          swal(errorMessage, err);
        }
      }
      return null;
    }
  }


  getAccountSummary = async () => {

    this.setState({ loading: true });
    let headers = await getApiHeader();
    let res = await axios.get(`${SERVER_URL}nbn-my-account-summary`, { headers }).then(async response => {
      if (response.data.data.customMessage) {
        if (response.data.data.customMessage != 'undefined') {
          this.setState({ customMesssge: response.data.data.customMessage });
        }
      }

      let data = response.data.data.summary
      this.setState({ activate_status: data.activation_status })
      this.setState({ has_nbn_default_card: data.has_nbn_default_card })
      this.setState({ product_name: data.product_name })
      this.setState({ expiry_date: data.expiry_date })
      this.setState({ plan_details: data.plan })
      this.setState({ customer_details: data.customer })
      this.setState({ service_details: data.service })
      this.setState({ plan_remaining_days: data.remaining_days === null ? 0 : data.remaining_days })
      this.setState({ serviceId: data.service_id })
      this.setState({ payNow: data.show_nbn_pay_now_button })
      this.setState({ loading: false })
      this.setState({ disconnection_status: data.disconnection_status })
      this.setState({ suspended_status: data.suspended_status })
      this.setState({ reactivation: data.service_reactivation_in_progress })


      await sessionStorage.setItem('nbn_default_card', data.has_nbn_default_card)
      let nbn_activation_id = data.nbn_activation_id;
      sessionStorage.setItem('nbn_activation_id', nbn_activation_id);
      
      this.setState({ loading: false });

    }).catch(error => {
      this.setState({ loading: false });
      if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

        swal({
          text: error.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
          icon: "warning"
        }).then(() => {

          this.handleLogout();

        });
      }
      else {
        swal("Internal Server Error", "", "warning");
      }
    });
  }

  activateOrder = async () => {
    if (this.state.has_nbn_default_card === false) {
      swal({
        text: 'Please add your card details',
        allowOutsideClick: false,
        closeOnClickOutside: false,
        icon: "warning"
      })
      return;
    }
    this.setState({ loading: true });
    let headers = await getApiHeader();
    const post_data = {
      activation_id: sessionStorage.getItem('nbn_activation_id'),
      is_check_age_bar_checked: this.state.is_check_age_bar_checked,
      is_check_transfer_acknowledgement: this.state.is_check_transfer_acknowledgement,
      is_service_qualification_check: this.state.is_service_qualification_check,
      is_check_holder_acknowledgement: this.state.is_check_holder_acknowledgement,
      is_service_validate_home_phone_check: this.state.is_service_validate_home_phone_check,


    };
    let res = await axios.post(`${SERVER_URL}nbn-convert-to-order`, post_data, { headers }).then(response => {
      if (response.data.data.customMessage) {
        if (response.data.data.customMessage != 'undefined') {
          this.setState({ customMesssge: response.data.data.customMessage });
        }
      }
      let data = response.data.data
      this.setState({ loading: false });
      swal('Activation initiated Successfully', "", "success");
      this.getAccountSummary();
    }).catch(error => {
      this.setState({ loading: false });
      if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

        swal({
          text: error.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
          icon: "warning"
        }).then(() => {

        });
      }
      else {
        swal("Internal Server Error", "", "warning");
      }
    });
  }
  addPayment = async () => {
    this.fetchClientToken();
    this.setState({ addPaymentCard: true })
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      // is_term_and_condition_checked: event.target.checked
      [name]: checked,
    });
  };


  addNbnCard = async () => {
    if (this.state.is_term_and_condition_checked === false) {
      swal({
        title: "Please check the terms and conditions",
        content: "Please check the terms and conditions",
        icon: "error"
      });
      return;
    }
    this.setState({ loading: true });
    let payment_nonce = null;

    try {
      const { nonce } = await this.instance.requestPaymentMethod();
      payment_nonce = nonce;
    }
    catch (error) {
      this.setState({ loading: false });
      return;
    }

    let post_data = {
      nonce: payment_nonce,
      default: true
    };

    try {
      let headers = await getApiHeader();
      let res = await axios.post(`${SERVER_URL}payments/nbn-update-card`, post_data, { headers });
      let data = res.data;

      swal(data.message, "", "success");
      this.getAccountSummary();
      this.setState({ loading: false, addPaymentCard: false })
    }
    catch (err) {

      this.setState({ loading: false });

      if (err) {
        let errorMessage = '';

        if (err.response && err.response.data && err.response.data.message) {
          if (typeof (err.response.data.message) === 'object') {

            swal({
              title: "Error",
              content: "Error occurred in add payment method",
              icon: "error"
            });

          }
          else {
            errorMessage = err.response.data.message;
            swal(errorMessage, "error");

          }
        }
        else {
          errorMessage = err;
          swal(errorMessage, err);
        }
      }
    }
  }


  handleClose = () => this.setState({ model_visibility: false });

  payNowHandler = async () => {
    const post_data = {
      nbn_activation_id: sessionStorage.getItem('nbn_activation_id')
    };
    try {
      let headers = await getApiHeader();
      let res = await axios.post(`${SERVER_URL}nbn/get_nbn_pay_now_details`, post_data, { headers });
      let data = res.data;
      // this.setState({
      //   model_visibility: true,
      //   changeRequest: res.data.data.pending_plan_change_request,
      //   planName: res.data.data.plan.plan_display_name,
      //   planAmount: res.data.data.payable_amount,
      //   credit: res.data.data.nbn_credit_discount,
      //   gstPlanAmount: res.data.data.gst_inclusive_payable_amount,
      //   nbnStatus: res.data.data.nbn_status
      // })
      if (res.status == 200) {
        swal({
          title: "Plan Details",
          content:
            <div>
              {/* <p>Change Request: ${res.data.data.pending_plan_change_request}</p> */}
              <p>Plan Name: {res.data.data.plan.plan_display_name}</p>
              {/* <p>Plan Amount: ${res.data.data.payable_amount}</p> */}
              {/* <p>Credit: ${res.data.data.nbn_credit_discount}</p> */}
              <p>Total Amount: ${res.data.data.gst_inclusive_payable_amount}</p>
              {/* <p>NBN Status: ${res.data.data.nbn_status}</p> */}
              <div>
                {this.state.changeRequest && (
                  <h5> you requested to change plan to {this.state.planName} ,during the previous cycle </h5>)}
              </div>
              {this.state.nbnStatus === "disconnected" && (
                <p>Please be aware that the reconnection request may take 2-5 business days to be completed'.</p>
              )}
            </div>
          ,
          icon: "",
          buttons: {
            cancel: "Cancel",
            submit: "Confirm & Submit"
          }
        })
          .then((value) => {
            if (value === "submit") {
              this.confirmHandler()
            } else {
              // Handle cancel action
            }
          });
      }
      else {
        swal("Internal Server Error", "", "warning");
      }
    }
    catch (err) {
      // console.log("err", err.response.data.message)
      this.setState({ loading: false });
      if (err) {
        let message = err.response.data.message
        swal({
          title: message,
          content: "Error occurred please try again later",
          icon: "error"
        });
      }

    }
  }

  confirmHandler = async () => {
    try {
      this.setState({
        model_visibility: false,
        payNow: false, loading: true
      });

      const post_data = {
        nbn_activation_id: sessionStorage.getItem('nbn_activation_id')
      };
      let headers = await getApiHeader();
      let res = await axios.post(`${SERVER_URL}nbn/nbn_pay_now_confirm`, post_data, { headers });
      let data = res.status;
      this.setState({ loading: false });
      swal(res.data.message, '', "success");
      this.getAccountSummary();
    } catch (err) {
      this.setState({ loading: false, payNow: true, });
      if (err) {
        let message = err.response.data.message
        swal({
          title: message,
          content: "",
          icon: "error"
        });
      }
    };
  }


  checkServiceAcknowledgement = async () => {
    this.setState({ add_service_acknowledgement: true })
  }


  validateCheckboxes = () => {
    const { is_check_age_bar_checked, is_check_transfer_acknowledgement, is_service_qualification_check, is_check_holder_acknowledgement, is_service_validate_home_phone_check,validate_service_acknowledgement } = this.state;
    if (
      validate_service_acknowledgement &&
      is_check_age_bar_checked &&
      is_check_transfer_acknowledgement &&
      is_service_qualification_check &&
      is_check_holder_acknowledgement && 
      is_service_validate_home_phone_check) {
     
      this.setState({ add_service_acknowledgement: false });
      this.activateOrder()

    } else {
      this.setState({ validate_service_acknowledgement: true });
    }
  };


  render() {
    const { is_check_age_bar_checked, is_check_transfer_acknowledgement, is_service_qualification_check, is_check_holder_acknowledgement,is_service_validate_home_phone_check, validate_service_acknowledgement } = this.state;
  
    return (
      <>

        {(this.state.loading === true) ? <PageLoader /> : null}

        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Account Summary</h3>
              </div>
            </div>

            {this.state.activate_status === '1' || this.state.activate_status === "9" || this.state.reactivation === true ?
              <>
                {/* <div className='row '>
                  <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1"><div class="customMsg"><p>Welcome to Telsim </p>
                  {this.state.payNow && (
                        <button type="button" className="btn btn-danger btn-data btn-lg" onClick={this.payNowHandler}>
                          Renew Now
                        </button>
                      )}
                  </div>
                  </div>
                  <div className='col-lg-12 col-xl-12 order-lg-1 order-xl-1'>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                    <div className="customMsg d-flex justify-content-between align-items-center p-3 border">
                      <p className="mb-0">Welcome to Telsim</p>
                      {this.state.payNow && (
                        <button
                          type="button"
                          className="btn btn-danger btn-data btn-lg"
                          onClick={this.payNowHandler}
                        >
                          Renew Now
                        </button>
                      )}

                    </div>
                  </div>
                </div>
                <div className="customMsg">
                  <div className="row py-4">
                    <div className="col-lg-1 py-4">
                      <center><img className="user-icon" src={maleUserImg} /></center>
                    </div>
                    <div className="col-lg-6 py-4">
                      <div className="nbn-data-content">
                        <strong>{this.state.customer_details.sal} {this.state.customer_details.firstName} {this.state.customer_details.surname}</strong>
                        <p>{this.state.customer_details.username}</p>
                        <p>  <strong>Service Address  :</strong> {this.state.service_details.service_address} </p>
                      </div>
                    </div>
                    <div class="col-lg-2 py-4"></div>
                    <div class="col-lg-3 py-4">
                      <div className="nbn-data-content">
                        <label> Service ID </label>
                        <select id="serviceId" class="form-control ">
                          <option value="service2" selected disabled>{this.state.serviceId}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 nbn-service-section" >
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon2-paper-plane kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Current Plan :</strong> {this.state.plan_details.website_plan_name}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center>

                            <i className="flaticon-download-1 kt-font-brand" style={{ fontSize: '30px' }}></i>
                          </center>
                          <strong>Download Speed :</strong> {this.state.plan_details.download_speed && this.state.plan_details.download_speed + 'Mbps'}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center>

                            <i className="flaticon-up-arrow-1 kt-font-brand" style={{ fontSize: '30px' }}></i>
                          </center>
                          <strong>Upload Speed :</strong> {this.state.plan_details.upload_speed && this.state.plan_details.upload_speed + 'Mbps'}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon-profile-1 kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Status :
                            {this.state.disconnection_status ? "Inactive" : this.state.suspended_status ? "Inactive" : "Active"}
                          </strong>
                          {/* <div style={{ color:"#C11D6C" }}>
                          {this.state.disconnection_status ? " Service Disconnected" : this.state.suspended_status ? "Service Suspended" : "Service Reactivation In Progress"}
                             </div> */}
                          <div style={{ color:"#C11D6C" }}>
                            {this.state.reactivation
                              ? "Service Reactivation In Progress"
                              : this.state.disconnection_status
                                ? "Service Disconnected"
                                : this.state.suspended_status
                                  ? "Service Suspended"
                                  : ""
                            }
                          </div>

                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section" >
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon-time-1 kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          {/* <strong> {this.state.plan_remaining_days< "0"?  'Expired':'Expiry'}</strong>:{this.state.expiry_date} */}

                          <strong>
                            {this.state.plan_remaining_days < 0 ? 'Expired' : 'Expiry'}
                          </strong>
                          : {this.state.expiry_date}


                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon2-gift-1 kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Data Allowance :</strong> Unlimited
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon2-calendar-2 kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          {/* <strong>Days Remaining :</strong> {this.state.plan_remaining_days} */}
                          <strong>Days Remaining:</strong> {this.state.plan_remaining_days < 0 ? 0 : this.state.plan_remaining_days}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon-coins kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Telsim NBN Credits :</strong> ${this.state.customer_details.nbn_credit_balance}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon2-pie-chart-2 kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Service Type :</strong> {this.state.service_details.nbnFixedAccessTechnology}
                        </div>
                      </center>
                    </div>
                    <div class="col-lg-3 nbn-service-section ">
                      <center>
                        <div class="nbn-box-content">
                          <center><i className="flaticon2-user kt-font-brand" style={{ fontSize: '30px' }}></i></center>
                          <strong>Service id :</strong> {this.state.serviceId}
                        </div>
                      </center>
                    </div>
                  </div>





                  <div className="row">
                    <center>
                      {/* <button type="button" className="btn btn-danger btn-data btn-lg">Change Plan</button> */}

                      {/* 
                      {this.state.payNow ? (
                        <button type="button" className="btn btn-danger btn-data btn-lg" onClick={this.payNowHandler}>
                          Pay Now
                        </button>
                      ) : (
                        <Link to="/nbn_change_plan" className="btn btn-danger btn-data btn-lg">
                          Change Plan
                        </Link>
                      )} */}

                       {this.state.activate_status === '1' && !this.state.suspended_status && (
                        <Link to="/nbn_change_plan" className="btn btn-danger btn-data btn-lg">
                          Change Plan
                        </Link>
                      )} 

                    </center>
                  </div>
                </div>
              </>
              : this.state.activate_status === '6' || this.state.activate_status === '4' ?
                <>
                  {this.state.addPaymentCard === true ? (
                    <>
                      {/* <center> */}
                      <br />
                      <div className="kt-portlet__head-label">
                        <h5 className="kt-portlet__head-title">Please add card details for further step</h5>
                      </div>
                      <div className="kt-portlet__body " style={{ width: '770px' }}>
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div id="erralert" className="alert-danger col-lg-8"></div>
                            {(this.state.clientToken !== null) ?
                              <div>
                                <DropIn
                                  options={{ authorization: this.state.clientToken, card: { cardholderName: { 'required': true } } }}
                                  onInstance={(instance) => (this.instance = instance)}
                                />
                                <div className="form-group  row">
                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label className="kt-option">
                                      <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                        <input type="checkbox" name="is_term_and_condition_checked" id="authorised_check" required checked={this.state.is_term_and_condition_checked} onChange={this.handleCheckboxChange} />
                                        <span></span>
                                      </label>
                                      <span className="kt-option__label">
                                        <span className="kt-option__head">
                                          <span className="kt-option__title">
                                            Your card will be charged 1 AUD for card preauthorization. This pending transaction will be voided by your bank automatically within 7-14 business days. This payment method will be used for all future payments for this account. Payment method can be updated anytime in Telsim My Account.

                                            {/* I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity. */}
                                          </span>

                                        </span>
                                      </span>

                                    </label>
                                    {/* <label>Your card will be charged 1 AUD for card preauthorization. This pending transaction will be voided by your bank automatically within 7-14 business days. This payment method will be used for all future payments for this account. Payment method can be updated anytime in Telsim My Account.</label> */}
                                  </div>
                                </div>
                              </div>

                              : <center> <Loader
                                type="Bars"
                                color="#C11D6C"
                                secondaryColor="white"
                                height={80}
                                width={80} /> </center>}
                          </div>
                        </div>
                      </div>

                      <button className="btn btn-brand" id="saveBtn" onClick={this.addNbnCard} >Save & Submit</button>
                      {/* </center> */}
                    </>) :
                    this.state.add_service_acknowledgement === true ?
                      <>
                        <div className="kt-portlet__head-label">
                          <br />
                          <h4 className="kt-portlet__head-title ">New Connection/Transfer/Additional Line Telsim NBN Service Acknowledgement </h4>
                          <br />
                        </div>
                        <div className="kt-portlet__body " style={{ width: '100%' }}>
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group  row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <label className={`kt-option ${validate_service_acknowledgement && !is_check_age_bar_checked ? 'invalid' : ''}`}>
                                    {/* <label className='kt-option invalid'> */}
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                      <input type="checkbox" name="is_check_age_bar_checked" id="authorised_check" required checked={this.state.is_check_age_bar_checked} onChange={this.handleCheckboxChange} />                                        <span></span>
                                    </label>
                                    <h5>I, {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`}  understand that I must be over 18 years old to purchase this broadband service.
                                    </h5>
                                  </label>
                                  {validate_service_acknowledgement && !is_check_age_bar_checked
                                    ?
                                    <>
                                      <span style={{ color:"#C11D6C", fontWeight: 'bold' }}>
                                        Please ensure your age  before proceeding.
                                      </span>
                                      <br />
                                    </>
                                    : ""}
                                  <label className={`kt-option ${validate_service_acknowledgement && !is_check_transfer_acknowledgement ? 'invalid' : ''}`}>
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                      <input type="checkbox" name="is_check_transfer_acknowledgement" id="authorised_check" required checked={this.state.is_check_transfer_acknowledgement} onChange={this.handleCheckboxChange} />                                        <span></span>
                                    </label>
                                    <h5>I, {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`} acknowledge that to transfer NBN service from another provider to Telsim or to take a new NBN connection/additional NBN line, I must be the authorised person / account holder of the service at {this.state.service_details.service_address} .
                                    </h5>
                                  </label>
                                  {validate_service_acknowledgement && !is_check_transfer_acknowledgement
                                    ?
                                    <>
                                      <span style={{ color:"#C11D6C", fontWeight: 'bold' }}>
                                        Please acknowledge to transfer service before proceeding.
                                      </span>
                                      <br />
                                    </>
                                    : ""}
                                  <label className={`kt-option ${validate_service_acknowledgement && !is_service_qualification_check ? 'invalid' : ''}`}>

                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                      <input type="checkbox" name="is_service_qualification_check" id="authorised_check" required checked={this.state.is_service_qualification_check} onChange={this.handleCheckboxChange} />                                        <span></span>
                                    </label>
                                    <h5>I, {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`}   understand that the service qualification check provides a preliminary result of plans and prices available in my area, which may vary as my order and connection are processed .
                                    </h5>
                                  </label>
                                  {validate_service_acknowledgement && !is_service_qualification_check
                                    ?
                                    <>
                                      <span style={{ color:"#C11D6C", fontWeight: 'bold' }}>
                                        Please ensure the service qualification details before proceeding.
                                      </span>
                                      <br />
                                    </>
                                    : ""}

                                  <br />

                                  <label className={`kt-option ${validate_service_acknowledgement && !is_service_validate_home_phone_check ? 'invalid' : ''}`}>

                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                      <input type="checkbox" name="is_service_validate_home_phone_check" id="authorised_check" required checked={this.state.is_service_validate_home_phone_check} onChange={this.handleCheckboxChange} />                                        <span></span>
                                    </label>
                                    <h5>I, {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`}   aware that Telsim do not provide Home Phone Services.
                                    </h5>
                                  </label>
                                  <i>Note: If you currently have a Home Phone (VoIP) service with your current provider, please DO NOT cancel. Terminating your existing phone service might result in the permanent loss of your phone number. </i>
                                  <br />
                                  {validate_service_acknowledgement && !is_service_validate_home_phone_check
                                    ?
                                    <>
                                      <span style={{ color:"#C11D6C", fontWeight: 'bold' }}>
                                        Please ensure the service validate details before proceeding.
                                      </span>
                                      <br />
                                    </>
                                    : ""}

                                  <br />
                                  <label htmlFor=""></label>
                                  <h4 className="">Telsim Account Holder Acknowledgement and Declaration</h4>
                                  <br />
                                  <label className={`kt-option-without-flex ${validate_service_acknowledgement && !is_check_holder_acknowledgement ? 'invalid' : ''}`}>
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                      <input
                                        type="checkbox"
                                        name="is_check_holder_acknowledgement"
                                        id="authorised_check"
                                        required
                                        checked={this.state.is_check_holder_acknowledgement}
                                        onChange={this.handleCheckboxChange}
                                      />
                                      <span></span>
                                      <h5>
                                        I, {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`} acknowledge that:
                                      </h5>
                                    </label>
                                    <ul style={{ paddingLeft: '30px' }}>
                                      <li><h5>I am the authorized account holder of the Telsim account {`${this.state.customer_details.firstName} ${this.state.customer_details.surname}`}.</h5></li>
                                      <li><h5>I have received and read the terms and conditions of the Telsim services provided to me.</h5></li>
                                      <li><h5>I understand and agree to abide by all the terms and conditions set forth by Telsim.</h5></li>
                                      <li><h5>I understand that any breach of the terms and conditions may result in the suspension or termination of my Telsim account.</h5></li>
                                      <li><h5>I understand that Telsim reserves the right to modify the terms and conditions at any time.</h5></li>
                                      <li><h5>I agree to comply with all applicable laws and regulations while using Telsim services.</h5></li>
                                      <li><h5>I understand that the payment method updated in Telsim My Account will be used for all future NBN payments and auto debit for plan renewals are enabled. Payment method can be updated any time in Telsim My Account.</h5></li>
                                    </ul>
                                  </label>

                                  {validate_service_acknowledgement && !is_check_holder_acknowledgement
                                    ?
                                    <>
                                      <br />
                                      <span style={{ color:"#C11D6C", fontWeight: 'bold' }}>
                                        Please ensure the holder acknowledgement details before proceeding.
                                      </span>
                                      <br />
                                    </>
                                    : ""}
                                  <br />
                                  <br />
                                  <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm" onClick={this.validateCheckboxes}>Confirm & Activate</button>

                                  {/* <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm" onClick={this.activateOrder}>Confirm & Activate</button> */}

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <div className="col-lg-10 col-xl-12 order-lg-1 order-xl-1">
                        <br />
                        <div className="kt-portlet__body">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <label> Service ID </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.serviceId} />
                                </div>
                                {/* </div>
                          <div className="form-group row"> */}
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <label> Address details (service address)</label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.service_details.service_address} />
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <label> Connection details (Access technology)</label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.service_details.nbnFixedAccessTechnology} />
                                </div>
                              </div>
                              <label><b>Plan details <hr /></b></label>
                              <div className="form-group row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                  <label> Plan name </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.plan_details.website_plan_name} />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12">
                                  <label> Download Speed </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.plan_details.download_speed && this.state.plan_details.download_speed + 'Mbps'} />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12">
                                  <label> Upload Speed </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.plan_details.upload_speed && this.state.plan_details.upload_speed + 'Mbps'} />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12">
                                  <label> Data </label>
                                  <input className="form-control" readOnly disabled type="text" value="Unlimited" />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                  <label> Total monthly cost </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.plan_details.website_sale_amount} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <label> Modem </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.product_name} />
                                </div>
                              </div>
                              <label><b>Personal details <hr /></b></label>
                              <div className="form-group row">
                                <div className="col-lg-6 col-md-3 col-sm-12">
                                  <label> Name </label>
                                  <input className="form-control" readOnly disabled type="text" value={`${this.state.customer_details.sal} ${this.state.customer_details.firstName} ${this.state.customer_details.surname}`} />
                                </div>
                                <div className="col-lg-3 col-md-2 col-sm-12">
                                  <label>Contact number</label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.service_details.endUserContactNumber} />
                                </div>
                                <div className="col-lg-3 col-md-2 col-sm-12">
                                  <label>Date of birth</label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.customer_details.dob} />
                                </div>
                              </div>
                              <label><b>Delivery person details <hr /></b></label>
                              <div className="form-group row">
                                <div className="col-lg-6 col-md-3 col-sm-12">
                                  <label> Name </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.service_details.person_accepting_delivery} />
                                </div>
                                <div className="col-lg-6 col-md-3 col-sm-12">
                                  <label> Address </label>
                                  <input className="form-control" readOnly disabled type="text" value={this.state.service_details.delivery_address} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className={`${this.state.service_details.fixedOrderAs === 'transfer' ? 'col-lg-3' : 'col-lg-6'} col-md-3 col-sm-12`}>
                                  <label> Connection Type </label>
                                  <input className="form-control" readOnly disabled type="text" value={(this.state.service_details.fixedOrderAs || '').toUpperCase()} />
                                </div>
                                {this.state.service_details.fixedOrderAs === 'transfer' && (
                                  <>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                      <label> Authority Date </label>
                                      <input className="form-control" readOnly disabled type="text" value={this.state.service_details.fixedCustomerAuthorityDate} />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                      <label> Fixed Product Resource Identifier</label>
                                      <input className="form-control" readOnly disabled type="text" value={this.state.service_details.fixedProductResourceIdentifier} />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                      <label> Fixed Service Provider Identifier</label>
                                      <input className="form-control" readOnly disabled type="text" value={this.state.service_details.fixedServiceProviderIdentifier} />
                                    </div>
                                  </>
                                )}

                                {this.state.service_details.fixedOrderAs === 'additional-line' && (
                                  <>
                                    <div className="col-lg-6 col-md-3 col-sm-12">
                                      <label> Connection Date </label>
                                      <input className="form-control" readOnly disabled type="text" value={this.state.service_details.fixedRequestedConnectionDate} />
                                    </div>
                                  </>
                                )}


                              </div>
                              <div className="form-group row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="kt-form__actions">
                                    {this.state.activate_status === '4' ? (
                                      <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm" disabled>Activation under Processing</button>
                                    ) : (
                                      this.state.has_nbn_default_card === true ? (
                                        <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm" onClick={this.checkServiceAcknowledgement}>Next</button>
                                      ) : (
                                        <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm" onClick={this.addPayment}>Next</button>
                                      )
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                  }
                </>

                : this.state.activate_status === '0' || this.state.activate_status === '8' || this.state.reactivation === false ?
                  <>
                    <br />
                    <div className='row '>

                      <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1"><div class="customMsg"><p>Verification under processing </p></div></div>
                      <div className='col-lg-12 col-xl-12 order-lg-1 order-xl-1'>
                      </div>
                    </div>
                  </>

                  : null
            }
          </div>
        </div >
        <Modal className="modal-fullscreen" size='lg' show={this.state.model_visibility} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Plan Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="modal-body" >
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td> <h5> Plan Name:{this.state.planName}</h5></td>
                  </tr>
                  <tr>
                    <td><h5> Plan Amount:{this.state.planAmount} </h5></td>
                  </tr>
                  <tr>
                    <td> <h5>Nbn Credit Discount:{this.state.credit}</h5> </td>
                  </tr>
                  <tr>
                    <td> <h5>Total Cost:{this.state.gstPlanAmount}(GST inculsive) </h5> </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {this.state.changeRequest && (
                <h5> you requested to change plan to {this.state.planName} ,during the previous cycle </h5>)}
            </div>
            {this.state.nbnStatus === "disconnected" && (
              <p>Please be aware that the reconnection request may take 2-5 business days to be completed'.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <button className="btn btn-brand" id="saveBtn" onClick={this.confirmHandler}>Confirm & Submit</button>
          </Modal.Footer>
          {/* </form> */}
        </Modal>
      </>
    );
  }
}

export default NbnAccountSummary;
