import React, { Component } from "react";
import { Link, withRouter ,Redirect} from "react-router-dom";
export default class GuestFooter extends Component {

    constructor(props) {
        super(props);
        this.state = { 
        page: '' ,
        };
    }


    

render()
{
    
    let urlElements = window.location.href.split('/');
    let urlElelement = (urlElements[3]);


    const date = new Date();
	const year = date.getFullYear();

    return(                        
    <div className="kt-grid__item">
        <div className="kt-login-v2__footer">
        <div className="kt-login-v2__link">
            <a target="_blank" href="https://telsim.com.au/website-privacy-policy" className="kt-link kt-font-brand">Privacy Policy</a>
            <a target="_blank" href="https://telsim.com.au/legals" className="kt-link kt-font-brand">Legals</a>
            <a target="_blank" href="https://telsim.com.au/contact-us" className="kt-link kt-font-brand">Contact Us</a>
        </div>
        <div className="kt-login-v2__info">
            <a  className="">Copyright © {year} | Telsim | All Rights Reserved</a>
        </div>
        </div>
    </div>);
}

    
}