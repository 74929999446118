import React, { Component } from "react";
import { Link } from "react-router-dom";
// import LogoImg from "./../../assets"
import LogoImg from './../../../src/assets/media/telsim/telsimnewlogo.png'; 
export default class GuestHeader extends Component {

    constructor(props) {
        super(props);
        this.state = { 
        page: '' ,
        };
    }

render()
{
    
    let urlElements = window.location.href.split('/');
    let urlElelement = (urlElements[3]);

    return(<div className="kt-grid__item  kt-grid--hor"> 
        <div className="kt-login-v2__head">
            <div className="kt-login-v2__logo">
                <Link to="/Login">
                    <img src={LogoImg} alt=""  style={{width:"150px"}}/>
                </Link>
            </div>
            <div className="kt-login-v2__signup"> 
                {this.state.page}
                {urlElelement == 'Activate'? <span>Already have an account?</span> :null }
                {urlElelement == 'Activate'?
                <Link to="/" className="kt-link kt-link--brand" >Sign In</Link>
                : 
                <Link to="/Activate" className="kt-link kt-link--brand" >Activate Your Number Now</Link>
                }


            </div>
        </div> 
    </div>);
}

    
}