import React, { Component } from "react";  

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import {ClipboardData ,Telephone,Messenger} from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import axios from 'axios';    
import {getApiHeader} from '../../utils/CommonFunctions';
import PageLoader from './../Loader/loader';
const SERVER_URL 	= process.env.REACT_APP_SERVER_URL; 

 

export default class UsageHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
            service_type: "",
            selectedTab: "Call",
            customer_activations: [],
            loading:false
        };
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    getActivations = async () => {
		let headers 	= await getApiHeader();
		let res 		= await axios.get(`${ SERVER_URL }user_activations`, { headers });
		let data 		= res.data.data.list; 
		let selected_number = '';
		if(data.length > 0){
			selected_number = data[0].activation_id;
		}else{ 
			selected_number = '';
		} 
		await sessionStorage.setItem('selected_number', selected_number);
        console.log(sessionStorage.getItem('selected_number'));
		this.setState({
			selected_number: selected_number, 
		}); 
		this.setState({ customer_activations: data });    
        this.setState({loading:false});
	};

    receivedData = async (type,offset) => {  
        let headers         = await getApiHeader();
        let activation_id   = sessionStorage.getItem('selected_number'); 
        const post_data     = { activation_id: activation_id , type:type , current_page : offset, per_page : this.state.perPage};
        axios
            .post(`${ SERVER_URL }usage/history`,post_data, { headers })
            .then(res => {
  
                const total_record_count = res.data.data.total_record_count;
                const data = res.data.data.records;
                //const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                

                if(type=="Call"){
                    const postCall = data.map((usage,i) => 
                        <React.Fragment> 
                            <Card className="usage_card" border="danger">
                                <Card.Body> 
                                    <Row>
                                        <Col xs lg="1">
                                            <Telephone size={40} color="#C11D6C"/>
                                        </Col>   
                                        <Col xs lg="3">
                                            <Card.Title> 
                                                {usage.destination}</Card.Title> 
                                                
                                        </Col> 
                                        <Col xs lg="3">
                                            <Card.Text>
                                                {usage.date_start_format}
                                            </Card.Text> 
                                        </Col>
                                        <Col xs lg="3"> 
                                            <Card.Text>Duration : {usage.duration_data}</Card.Text> 
                                        </Col>
                                        <Col xs lg="2">
                                            <Card.Text>${usage.price_charge} 
                                            {/* <br/> ({usage.tariff_description}) */}
                                            </Card.Text>  
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </React.Fragment>)
                    

                    this.setState({
                        pageCount: Math.ceil(total_record_count / this.state.perPage),
                    
                        postCall
                    })
                }else if(type=="Data"){
                    const postData = data.map((usage,i) => 
                        <React.Fragment> 
                            <Card className="usage_card" border="danger">
                                <Card.Body> 
                                    <Row>
                                        <Col xs lg="1">
                                            <ClipboardData size={40} color="#C11D6C"/>
                                        </Col>   
                                        {/* <Col xs lg="3">
                                            <Card.Title> 
                                                {usage.destination}</Card.Title> 
                                                
                                        </Col>  */}
                                        <Col xs lg="3">
                                            <Card.Text>
                                                {usage.date_start_format}
                                            </Card.Text> 
                                        </Col>
                                        <Col xs lg="3"> 
                                            <Card.Text>Duration : {usage.duration_data}</Card.Text> 
                                        </Col>
                                        <Col xs lg="2">
                                            <Card.Text>${usage.price_charge} </Card.Text>  
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </React.Fragment>)
                    

                    this.setState({
                        pageCount: Math.ceil(total_record_count / this.state.perPage),
                    
                        postData
                    })
                }else if(type=="SMS"){
                    const postSMS = data.map((usage,i) => 
                        <React.Fragment> 
                            <Card className="usage_card" border="danger">
                                <Card.Body> 
                                    <Row>
                                        <Col xs lg="1">
                                            <Messenger size={40} color="#C11D6C"/>
                                        </Col>   
                                        <Col xs lg="3">
                                            <Card.Title> 
                                                {usage.destination}</Card.Title> 
                                                
                                        </Col> 
                                        <Col xs lg="3">
                                            <Card.Text>
                                                {usage.date_start_format}
                                            </Card.Text> 
                                        </Col>
                                        {/* <Col xs lg="3"> 
                                            <Card.Text>Duration : {usage.duration_data}</Card.Text> 
                                        </Col> */}
                                        <Col xs lg="2">
                                            <Card.Text>${usage.price_charge}</Card.Text>  
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </React.Fragment>)
                    

                    this.setState({
                        pageCount: Math.ceil(total_record_count / this.state.perPage),
                    
                        postSMS
                    })
                }
            });
    }

    handlePageClick = (e) => {
        const type          = this.state.service_type;
        const selectedPage  = e.selected;
        const offset        = selectedPage * this.state.perPage; 
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData(type,offset)
        });
  
    };
 
    handleTabChange = (type) => { 
        this.setState({ service_type: type });
        this.setState({ selectedTab: type });
        this.receivedData(type,"0")  
    }

    handleChange =  (e) => { 
		var active_number = e.target.value;  
		this.setState({
			selected_number: active_number, 
		}); 
		sessionStorage.setItem('selected_number', active_number); 
		this.receivedData("Call","0");  
	};

  
    componentDidMount() {
        this.setState({ service_type: "Call" });
        this.receivedData("Call","0");
		this.getActivations();
        this.setState({loading:true});
    }


    render() { 
  return (
    <>
    {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label"> 
                                        <h3 className="kt-portlet__head-title">Usage History</h3> 
                                    </div>
                                    <select className="form-control kt_selectpicker" defaultValue={sessionStorage.getItem('selected_number')} onChange={this.handleChange} name="activation" style={{ width: '100px' }}>

                                        { this.state.customer_activations.map(person => <option className="m--bg-success kt-font-inverse-success" value={person.activation_id}>{person.mobile_number}</option>)} 

                                    </select>
                                </div>
                                <Container fluid style={{ padding: '20px' }}>
                                    <Row>
                                        <Tabs defaultActiveKey={this.state.selectedTab} id="uncontrolled-tab-example" className="mb-3" onSelect={this.handleTabChange}>
                                            <Tab eventKey="Call" title="Call">
                                                {/* <Row className="date-card">
                                                    <h5>17 Oct 2021</h5>
                                                </Row>  */}
                                                 
                                                {this.state.postCall}
                                                <ReactPaginate 
                                                    previousLabel={"← Previous"} 
                                                    nextLabel={"Next →"} 
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}/>
                                            </Tab>
                                            <Tab eventKey="Data" title="Data" >
                                                {this.state.postData}
                                                <ReactPaginate 
                                                    previousLabel={"← Previous"} 
                                                    nextLabel={"Next →"} 
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}/>
                                            </Tab>
                                            <Tab eventKey="SMS" title="SMS" >
                                                {this.state.postSMS}
                                                <ReactPaginate 
                                                    previousLabel={"← Previous"} 
                                                    nextLabel={"Next →"} 
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}/>
                                            </Tab>
                                        </Tabs>
                                    </Row>
                                </Container>
                            </div>   
                        </div>
                    </div>
                </div>
            </div> 
        </div>
                    
	</>
   )
}
}