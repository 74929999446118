
import React, { Component } from 'react';
import axios from "axios"
import { getApiHeader } from '../../../utils/CommonFunctions';
import PageLoader from './../../Loader/loader';
import swal from '@sweetalert/with-react';
import { withRouter } from 'react-router-dom';



const SERVER_URL = process.env.REACT_APP_SERVER_URL



class NbnChangePlans extends Component {

  constructor(props) {
    super(props)

    this.state = {
      plans: [],
      defaultPlanId: null,
      resetPlan: false,

      
      
    };


  }

  componentDidMount() {
      const { avaliblePlan}=this.props
       if( avaliblePlan.length>0){
         this.getPlans( avaliblePlan)
       }else{
        this.getPlans([])
       }

  }
  
  componentDidUpdate(prevProps) {
    // Check if AvailablePlan or addressVerify changed
    if (this.props. avaliblePlan !== prevProps. avaliblePlan || this.props.addressVerify !== prevProps.addressVerify) {
      if (this.props. avaliblePlan.length > 0) {
        this.getPlans(this.props. avaliblePlan);
      } else {
        this.getPlans([]);
      }

      // Handle address verification changes
      if (this.props.addressVerify !== this.state.resetPlan) {
        if (this.props.addressVerify === false) {
          this.getPlans(this.props. avaliblePlan);
        }
        this.setState({ resetPlan: this.props.addressVerify });
      }
    }
  }


  getPlans = async (plans) => {
    const  headers = await getApiHeader();
     
    const payload = {
     "plan_array":plans
    }

    const resp = await axios.post(`${SERVER_URL}nbn/get_nbn_plans`, payload, { headers}).then(async response => {
      if (response.status === 200) {
           const data= response.data.data
            if(data&&data.plans.length>0){
                const latestPlan=data.plans[data.plans.length-1];
                 this.setState({defaultPlanId:latestPlan.plan_id , plans:data.plans })
                 this.props.getDefaultPlan(latestPlan)
            }
      }
    }).catch(error => {
      //this.setState({ status: "error" })
      console.error("Error loading plans:",error);

    })

  }

          PlanSelectHandler=(plan,planId)=>{
         this.setState({defaultPlanId:planId })
         this.props.getDefaultPlan(plan)

          }


  render() {
    const status = this.state.status
    const array = this.state.plans
    

    return (
      <div> 
          <div className="container" id="planSelectionBox">
            <section className="common-section">
              <div className="row">
                <div className="header-title">
                  <h2 className="h3"> 2 .Choose Your Telsim Home NBN<sup>&reg;</sup> Unlimited Data Plans</h2>
                  <p style={{ textAlign: "left" }}>NBN<sup>&reg;</sup> plans ranging from 12Mbps to 1000Mbps are available for eligible addresses.</p>
                </div>

                {this.state.plans.map((plan, index) => (
                   
                  <div key={index} className="col-lg-4 col-md-6 ">
                    <div className="pricing-card">
                      <div className="plan-bg">
                      {plan.offer_amount== 0 ?     
                    <p class="custom-paragraph">Popular Plan</p>:""
                }
                <img src={plan.plan_card_icon} alt="Plan Icon" className={`plan-icon ${plan.offer_amount == 0 ? 'popular-flag' : ''}`} />
                        {/* <img src={plan?.plan_card_icon} alt="Plan Icon" /> */}
                        {/* <h2>${`${plan.website_sale_amount}`}</h2> */}
                        
                   {plan.offer_flag === 1 ? (
                      <>
                        <h2 className="strike-amount">
                        <span className={`strikethrough ${plan.offer_amount == 0 ? 'popular-strike' : ''}`}>${plan.sell_amount}</span>
                        {/* <span className="strikethrough">${plan.sell_amount}</span> */}
                        </h2>
                        <h2>
                          ${plan.offer_amount}
                        </h2>
                        <p style={{ color: 'white', fontSize: '0.8rem' }}>
                          *First month only. Ongoing <br></br>${plan.sell_amount} monthly.
                        </p>
                      </>
                    ) : (
                      <>
                        <h2 className="discount-amount">${plan.sell_amount}</h2>
                        <h4>Monthly</h4>
                      </>
                    )}

                      </div>
                      {/* <h3>NBN Home<br />{plan.website_plan_name.replace('NBN Home', '')}</h3> */}
                      <h5 style={{ marginTop: "10px" }}>NBN<sup>&reg;</sup> Home<br />{plan.website_plan_name.replace('NBN Home', '')}</h5>
                      <h3>NBN<sup>&reg;</sup> <small>{plan.download_speed}/{plan.upload_speed} Mbps</small></h3>
                      <p>Typical evening speed</p>
                      <p>(7pm-11pm)</p>
                      <center>
                        <table className="table table-borderless price-table">
                          <thead>
                            <tr>
                              <th valign="top"> {plan?.download_speed} Mbps</th>

                              <th valign="top" >{plan?.upload_speed} Mbps</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td valign="top">DOWNLOAD</td>

                              <td valign="top">UPLOAD</td>
                            </tr>
                          </tbody>
                        </table>
                      </center>
                      <br />
                      <div className="data-pack">
                        <b>{plan.data===null ? plan.data_text : `${plan.data} GB`}</b>
                      </div>
                      <div className="data-pack-description">
                   <b>DESIGNED FOR</b>
                   <br/>
                  <br/>
                  <ul>
                 {plan.description.map((value, index) => (
                  <li><i class="fa fa-check-circle-o" aria-hidden="true"></i>{value}</li>
                  ))}
                </ul></div>
                      {/* <p><a href="https://www.telsim.com.au/website-terms-conditions/" target="_blank">Terms & Conditions</a></p>
                       */}
                       <button className="button-57" style={{ width: '100%' }} onClick={() => this.PlanSelectHandler(plan, plan.plan_id)}>
                          {this.state.defaultPlanId=== plan.plan_id ?
                    <><span className="text">✓ SELECTED</span><span>✓ SELECTED</span></> :
                    <><span className="text">SELECT</span><span>SELECT</span></>}
                     </button> 


                      {/* <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/critical-information-summary/" target="_blank">Critical Information Summary</a></p> */}
                      {plan.offer_flag === 1 ?
                        <p style={{ marginTop: '10px' }}><a href={plan.terms_and_condition_path} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                        : ""}
                      <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/critical-information-summary/" target="_blank">Critical Information Summary</a></p>
                      <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/wp-content/uploads/2024/05/Telsim_NBN_Key_Facts_Sheet.pdf"target="_blank">Fact Sheet</a></p>
                      
                    
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        

      </div>
    )
  }
}
export default withRouter(NbnChangePlans)


