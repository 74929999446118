import React from 'react';
import stopsign from "../assets/img/stopsign.png"

const ForbiddenPage = () => {
  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#C11D6C'
  };

  const hrStyle = {
    width: '50%',
    border: '1px solid white'
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>Access Denied</h1>
      <hr style={hrStyle} />
      <h3>You don't have permission to access this url </h3>
      <h3 style={{ color: '#C11D6C' }}>🚫🚫🚫🚫</h3>
      <h6>Error code: 403 Forbidden</h6>
      
    </div>
  );
};

export default ForbiddenPage;
