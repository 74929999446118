import React, { Component } from 'react';
import axios from 'axios';   
import {getApiHeaderGuest ,getApiHeader, removeUserSession, getActivationInactivity,getActivationAttemptDataYourSim} from '../../../../utils/CommonFunctions';
import swal from 'sweetalert';
import { WidgetInput } from "react-addressfinder";
import SimpleReactValidator from 'simple-react-validator';
import {withRouter} from "react-router-dom";
import PageLoader from './../../../Loader/loader';
//import profile_image from './../../../assets/media/users/computer-icons-user-profile-avatar.jpg'
import esim_image from './../../../../assets/media/esim/esim.png';
import physical_image from './../../../../assets/media/esim/physical.png';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;
const isValidNo = /^[0-9]*$/;
//const ESIM_ACTIVATION_TYPE = process.env.ESIM_ACTIVATION_TYPE;


const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress) => {
           onChange(fullAddress);
        }}
      />
      )
}



export class YourTelsimSim extends Component {

    constructor(props) {

        super(props);

    this.validator = new SimpleReactValidator();


    this.state = {
        sim: '',
        oldsim:0,
        sim_fname:'',
        sim_lname:'',
        sim_email:'',
        sim_address:'',
        errormsg:'',
        eSIMErrorMsg: '',
        address_full:'',
        enquiry_id:'',
        loading:false,
        simType: 'physical',
        esim_activation_type: 'both',
        simVoucher: '',
        simCode: ''
    }
}
    

    componentDidMount() {
        this.checkActivationInactivity();  
        this.getActivationAttemptData();  
    }


    checkActivationInactivity = async () => {
        let inactiveData     = await getActivationInactivity(); 
        if(inactiveData.expired != 'undefined'){
            if(inactiveData.expired == 1){
                swal("Your session expired, Please try again", "", 'error');
                setTimeout(function() {
                      window.location.reload();
                    }, 2000);
            }else{
                if(inactiveData.inactiveMaxTime != 'undefined'){
                    if(inactiveData.inactiveMaxTime > 0){
                        let maxInactiveTime = inactiveData.inactiveMaxTime;
                        // repeat check inactive api here after maxInactiveTime millisecond
                        setTimeout(() => this.checkActivationInactivity(), maxInactiveTime);
                    }
                }

            }
        }        
    };



    getActivationAttemptData = async () => {
        this.setState({loading:true});
        let attempt_data     = await getActivationAttemptDataYourSim (); 
        if(attempt_data.activation_attempt !=null)
        {
        this.setState({ sim: attempt_data.activation_attempt.sim_number, esim_activation_type: attempt_data.activation_attempt.esim_activation_type});  
        }
        this.setState({loading:false});
    };



    setaddressFinder= async (full_address) => {
        this.setState({ address_full: full_address });
    };


    simCheck = (event) => {
        if(event.target.value.match(isValidNo)){
            this.setState({ sim: event.target.value});
        }else{
            console.log('false', event.target.value);
        }
    }

    simVoucherCheck = (event) => {
        if(event.target.value.match(isValidNo)){
            this.setState({ simVoucher: event.target.value});
        }else{
            console.log('false', event.target.value);
        }
    }

    simCodeCheck = (event) => {
        const value = event.target.value;
        const maxLength = 25;
        if (value.length > maxLength) {
            return; 
        }
        this.setState({ simCode: value });
    }


    mySubmitHandler = async (event) => {
        this.setState({loading:true}); 
        await sessionStorage.setItem('plan_id', 0);
        this.setState({ errormsg: '', eSIMErrorMsg: ''});
        event.preventDefault();  
        let post_data =   {   
            sim: this.state.simType == 'physical' ? this.state.sim : (this.state.simVoucher == '' ? "" : this.state.simVoucher),
            path: 'verify-sim',
            session_id : sessionStorage.getItem('activation_session_id')  ,
            sim_type: this.state.simType == 'physical' ? 'Physical Sim' : 'eSIM',
            nbn_activation_id: sessionStorage.getItem('nbn_activation_id'),
            sim_code : this.state.simCode
        };
        let headers     = await getApiHeader();
        let res         = await axios.post(`${ SERVER_URL }activationv2/verify/sim`,post_data, { headers }).then(response => {
            

        // 10-05-22  activation sim type check
        let bonus_data = '0';
        if(response && (response.data) && (response.data.data) && (response.data.data.simtype)){ 
                if(response.data.data.simtype =="port_in"){ bonus_data = '1'; }
        }
        if(response && (response.data) && (response.data.data) && (response.data.data.bonus_allow)){ 
                if(response.data.data.bonus_allow =="1"){ bonus_data = '1'; }
        }
        sessionStorage.setItem("activation_session_id", response.data.data.session_id);
        sessionStorage.setItem("showBonus", bonus_data);
        sessionStorage.setItem("simtype", this.state.simType);
        // 10-05-22


        this.props.nextStep();
        this.setState({loading:false});

        }).catch(error => {

            let errorstatus = '';
            if(error.response.data.data.error_status)
            {
            errorstatus = JSON.parse(error.response.data.data.error_status);
            }
            if(error.response.data.message)
            {
            const error_msg = JSON.stringify(error.response.data.message);
            }
            if(errorstatus == 1)
            { 
                const email = JSON.stringify(error.response.data.data.email);
                const emailid = email.slice(1, -1);
                this.setState({ sim_email: emailid}); 
                this.setState({ oldsim: 1});   
            }
            else
            {
                if(this.state.simType == 'physical'){
                    this.setState({ errormsg: 'Please enter a valid sim number.'});
                } else {
                    this.setState({ eSIMErrorMsg: 'Please enter a valid sim number.'});
                }
                
            }
            this.setState({loading:false});
        });
    }






    cancelOld = (event) => {
        this.setState({ oldsim: 0});
        this.setState({ address_full: '' });
        this.setState({ sim_fname: '' });
        this.setState({ sim_lname: '' });
        this.setState({ sim_email: '' });
    }

    oldSimFName = (event) => { this.setState({ sim_fname: event.target.value}); }
    oldSimLName = (event) => { this.setState({ sim_lname: event.target.value}); }
    oldSimAddress = (event) => { this.setState({ sim_address: event.target.value}); }

    myoldHandler = async (event) => { 
        event.preventDefault();  


        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            return;

        } 


        const post_data =   {   enquiry_sim: this.state.sim ,
                                enquiry_firstName: this.state.sim_fname ,
                                enquiry_lastName: this.state.sim_lname ,
                                enquiry_email: this.state.sim_email ,
                                enquiry_address: this.state.address_full ,
                                session_id : sessionStorage.getItem('activation_session_id')  ,
                            };
        let headers     = await getApiHeader();
        let res         = await axios.post(`${ SERVER_URL }activationv2/verify/oldsim`,post_data, { headers }).then(response => {
            // this.showMessages(response.data);
            let enqid=response.data.data.enquiry_id;
            this.setState({ oldsim: 2});
            this.setState({ enquiry_id: enqid });

        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                this.setErrorMessages(error.response.data.message,'warning');
            }
            else
            {
                swal("Internal Server Error", "", 'warning');
            }
            
        });
    }




     confirmOld = async (event) => { 
        event.preventDefault();  
        const post_data =   {   
                                session_id : sessionStorage.getItem('activation_session_id')  ,
                                enquiry_id : this.state.enquiry_id,
                            };
        let headers     = getApiHeader();
        let res         = axios.post(`${ SERVER_URL }activationv2/verify/oldsim_confirm`,post_data, { headers }).then(response => {
            // this.showMessages(response.data);
            swal({
            title: 'Confirmed with Given Details!',
            text: 'We will send new sim card.',
            icon: 'success'
            }).then(function() {
                       window.location.href = "https://telsim.com.au";
                    });

        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                this.setErrorMessages(error.response.data.message,'warning');
            }
            else
            {
                swal("Internal Server Error", "", 'warning');
            }
            
        });
    }




    showMessages = (obj) =>{
        let type='success';
        if((typeof(obj.error) !== 'undefined') && (obj.error==true))
        {
          type='warning';
        }
        alert(type);
        alert(obj.message);
        //swal(obj.message, "", type);
    }



    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type);

    }





    back = e => {
        e.preventDefault();
        this.props.prevStep()
    }



    render() {
        const { values, handleChange } = this.props;

        return (

            <div>
                
               {this.state.oldsim ==0 && 
                <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.mySubmitHandler}>
                <div className="kt-wizard-v2__content " data-ktwizard-type="step-content">
                    <div className="kt-heading kt-heading--md">Your Telsim SIM</div>
                    <div className="kt-separator kt-separator--height-xs"></div>
                    <div className="kt-form__section kt-form__section--first">
                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                            {<p>Choose SIM type <span style={{color: '#C11D6C'}}>*</span></p>}
                        </label>
                        <div className="form-group row simImgView simPadding">
                            <div className="col-lg-2 col-xl-2 simView" style={{border: this.state.simType === 'physical' ? '1px solid #C11D6C' : '1px solid #cccccc', height: 120, justifyContent: 'center', alignItems: 'center', display: 'flex'}} onClick={()=>{this.setState({simType: 'physical'})}}>
                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <img src={physical_image} alt="image" style={{width: 40, height: 30}}/>
                                    <label className="col-xl-12 col-lg-12 col-form-label" style={{textAlign: 'center'}}>Physical SIM</label>
                                </div>
                            </div>
                            <div className="col-lg-2 col-xl-2 esimView simView simleft" style={{border: this.state.simType === 'esim' ? '1px solid #C11D6C' : '1px solid #cccccc', height: 120, justifyContent: 'center', alignItems: 'center', display: 'flex'}} onClick={()=>{this.setState({simType: 'esim'})}}>
                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <img src={esim_image} alt="image" style={{width: 40, height: 30}}/>
                                    <label className="col-xl-12 col-lg-12 col-form-label" style={{textAlign: 'center'}}>eSIM</label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: 30}}>
                            <div className="col-lg-12">
                                {this.state.simType == 'physical' && (<div className="form-group ">
                                    <label>Enter the SIM number on the Telsim SIM card</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter SIM Number" aria-describedby="basic-addon1" name="sim" id="sim" onChange={this.simCheck} required={true} value={this.state.sim}/>
                                    </div>
                                    <div className="input-group">
                                        <br/>
                                        <span className="" role="alert" id="sim_error" style={{color:"#C11D6C",marginTop: '10px',paddingLeft: '5px'}}>{this.state.errormsg}</span>
                                    </div>
                                </div>)} 
                                {(this.state.simType == 'esim' && this.state.esim_activation_type != 'without_voucher') && (<div className="form-group ">
                                    {this.state.simType != 'esim' && (<label>Enter the SIM number on the Telsim SIM card (optional)</label>)}
                                    {this.state.simType != 'esim' && (<div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter SIM Number" aria-describedby="basic-addon1" name="sim" id="sim" onChange={this.simVoucherCheck} value={this.state.simVoucher}/>
                                    </div>)}
                                    <label>Enter code in order to verify the SIM</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter code in order to verify the SIM" aria-describedby="basic-addon1" maxLength={25}  name="simCode" id="simCode" required={true} onChange={this.simCodeCheck} value={this.state.simCode}/>
                                    </div>
                                    <div className="input-group">
                                        <br/>
                                        <span className="" role="alert" id="sim_error" style={{color:"#C11D6C",marginTop: '10px',paddingLeft: '5px'}}>{this.state.eSIMErrorMsg}</span>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>

                    
                    <br/>
                    <div className="kt-form__actions">
                       {/* <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp; */}
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next">Next Step</button>
                    </div>

                </div>      
                </form>   
                }




                {this.state.oldsim ==1 && 
                <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.myoldHandler}>
                        <div className="kt-form__section kt-form__section--first">
                         <div className="kt-heading kt-heading--md">Your Telsim SIM</div>
                         <div className="kt-separator kt-separator--height-xs"></div>
                            <div className="row oldsimform">
                                    
                                
                                     <p>This SIM card has expired. Please visit telsim.com.au to purchase new SIM card.</p>

                                     <br/>
                                     <br/>
                                    <div className="kt-form__actions">
                                       <button type="submit" className="btn btn-danger btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.cancelOld}>Back</button>&nbsp;
                                       
                                    </div>

                            </div>
                        </div>
                </form>
                }



                 {this.state.oldsim ==2 && 
                <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.confirmOld}>
                        <div className="kt-form__section kt-form__section--first">
                         <div className="kt-heading kt-heading--md">Your Telsim SIM</div>
                         <div className="kt-separator kt-separator--height-xs"></div>
                            <div className="row oldsimform">
                                    
                                
                                    <div className="form-group row" style={{marginBottom: '0'}}>
                                        <div className="col-xl-12 col-lg-12">
                                          <p className="oldhead" style={{textAlign: 'center',background: '#ff000012',color: '#000',fontWeight: '400',padding:'25px'}}>Telsim is now using parts of Telstra's 4G and 3G network. Your current Telsim SIM card doesn't support this. We will send you a new Telsim SIM to your address for free of cost. Please confirm to proceed. </p>
                                        </div>

                                       
                                    </div>

                                     <br/>
                                    <div className="kt-form__actions">
                                       <button type="submit" className="btn btn-success btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.cancelOld} style={{margin:'0 auto'}}>No Cancel</button>&nbsp;
                                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" style={{margin:'0 auto'}}>Yes, Proceed!</button>
                                    </div>
                                    <div className="container-fluid">
                                        <br/>
                                        <br/>
                                    </div>
                                    

                            </div>
                        </div>
                </form>
                }
                {(this.state.loading === true) ? <PageLoader/> : null}  
            </div>
        
        );
    }
}

export default withRouter(YourTelsimSim);