import React, { Component } from "react"; 
import Carousel from 'react-bootstrap/Carousel';
import Image1 from './../../../assets/media/login_slider/correctedSlider35gb.png';
import Image2 from './../../../assets/media/login_slider/correctedSlider120gb.png';
import Image3 from './../../../assets/media/login_slider/correctedSlider160gb.png';
import Image4 from './../../../assets/media/login_slider/newslider260.png';


// /var/www/Telsim/Telsim_MyAccount_React/src/assets/media/login_slider/newSlider30gb.png

// /var/www/Telsim/Telsim_MyAccount_React/src/assets/media/login_slider/newslider160gb.png
export default class PublicSlider extends Component {


    render()
    {
        return(

        <>
            <Carousel fade>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image1}
                    alt="Grab 35GB for 35 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image2}
                    alt="Grab 120GB for 55 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image3}
                    alt="Grab 160GB for 60 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image4}
                    alt="Grab 260GB for 330 dollar unlimited prepaid yearly plan"
                    /> 
                </Carousel.Item>
            </Carousel>
        </>

        )
    }

}