import React, { Component } from 'react';
import axios from 'axios';    
import swal from 'sweetalert';
import {getApiHeaderGuest , getActivationInactivity, getActivationAttemptDataForSetupProfile} from '../../../utils/CommonFunctions';
import { WidgetInput } from "react-addressfinder";
import "react-addressfinder/dist/widget.css";
import DatePicker from 'react-date-picker';
import SimpleReactValidator from 'simple-react-validator';
import PageLoader from './../../Loader/loader';

const app_environment   = process.env.REACT_APP_ENV;
const SERVER_URL        = process.env.REACT_APP_SERVER_URL;   
// const headers       = getApiHeaderGuest();


const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress, address) => {
           onChange(fullAddress,address);
        }}
      />
      )
}



export class SetupYourProfile extends Component {
    constructor(props) {

    super(props);

    let today   = new Date();
    let dd      = today.getDate();
    dd = (dd < 10) ? ("0" + dd) : dd;
    let mm      = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    mm = (mm < 10) ? ("0" + mm) : mm;
    let yyyy    = today.getFullYear();
    let maxyyyy = yyyy - 16;
    let minyyyy = yyyy - 110;

    this.validator = new SimpleReactValidator();

    this.state = {
        addressmask: '',
        address_full:'',
        profileFName:'',
        profileMName:'',
        profileLName:'',
        profileTitle:'',
        loading:true,
        profileDob:new Date(`${maxyyyy}-${mm}-${dd}`), 
        profileEmail:'',
        salutations_list:[],
        maxDob: new Date(`${maxyyyy}-${mm}-${dd}`),
        minDob: new Date(`${minyyyy}-${mm}-${dd}`),
        businessCustomer:false,
        companyDetails:null,
        companyName:'',
        tradingName:'',
        acn:'',
        abn:'',
        companyChecked:null,
        businessText:"Please note that this is for invoicing purposes only and won't change the service or product inclusions."
        }
    }


    componentDidMount() {
        this.setState({loading:true});
        this.checkActivationInactivity();  
        this.getActivationAttemptData();  
        this.getSalutationList();
    }

    checkActivationInactivity = async () => {
        let inactiveData     = await getActivationInactivity(); 
        if(inactiveData.expired != 'undefined'){
            if(inactiveData.expired == 1){
                swal("Your session expired, Please try again", "", 'error');
                setTimeout(function() {
                      window.location.reload();
                    }, 2000);
            }else{
                if(inactiveData.inactiveMaxTime != 'undefined'){
                    if(inactiveData.inactiveMaxTime > 0){
                        let maxInactiveTime = inactiveData.inactiveMaxTime;
                        // repeat check inactive api here after maxInactiveTime millisecond
                        setTimeout(() => this.checkActivationInactivity(), maxInactiveTime);
                    }
                }

            }
        }        
    };



    //////////////Get Providers API Call //////////////////////////
    getSalutationList = async () => {
		let headers 	= await getApiHeaderGuest();
		let res 		= await axios.get(`${ SERVER_URL }activation/fetch-salutations`, { headers });
		let data 		= res.data.data.salutations;  
		 
        this.setState({ salutations_list: data }); 
	};

    
    ///////////////////////////////////////////////////////
    getActivationAttemptData = async () => {
        let attempt_data     = await getActivationAttemptDataForSetupProfile(); 
        if(attempt_data.activation_attempt !=null)
        {
        this.setState({ profileTitle: attempt_data.activation_attempt.sal});  
        this.setState({ profileFName: attempt_data.activation_attempt.firstName});  
        this.setState({ profileMName: attempt_data.activation_attempt.middleName});  
        this.setState({ profileLName: attempt_data.activation_attempt.surname});  

        if(attempt_data.activation_attempt.dob)
        {
            this.setState({ profileDob: new Date(attempt_data.activation_attempt.dob)});   

        }
        else
        {
            this.setState({ profileDob: this.state.maxDob}); 
        }

        this.setState({ profileEmail: attempt_data.activation_attempt.email});

        if(this.state.profileEmail==null)
        {
        this.setState({ profileEmail: attempt_data.activation_attempt.username}); 
        }
        

        if(attempt_data.activation_attempt.address)
        { 
            this.setState({ addressmask: '1' }); 
            this.setState({ address_full: attempt_data.activation_attempt.address.address_full});
        }



        if(attempt_data.default_local_address)
        { 
            if(attempt_data.default_local_address !='undefined'){
                this.setState({ addressmask: '1' }); 
                this.setState({ address_full: attempt_data.default_local_address});
            }
        }





        if(attempt_data.activation_attempt.custType == 'BU'){
                
            this.setState({ businessCustomer: true });
            this.setState({ companyDetails: 'BU' });
            this.setState({ companyChecked: 'checked' });
            this.setState({ companyName: attempt_data.activation_attempt.company_name });
            this.setState({ tradingName: attempt_data.activation_attempt.trading_name });
            this.setState({ acn: attempt_data.activation_attempt.acn });
            this.setState({ abn: attempt_data.activation_attempt.abn });

        }







        }

        this.setState({loading:false});
    };




     setaddressFinder= async (full_address,address) => {
        this.setState({ address_full: full_address });
        this.setState({ addressmask: '1' });
    };


    profileFName = (event) => { this.setState({ profileFName: event.target.value});}
    profileMName = (event) => { this.setState({ profileMName: event.target.value});}
    profileLName = (event) => { this.setState({ profileLName: event.target.value});}
    profileTitle = (title) => { this.setState({ profileTitle: title});}

    profileDob = (value) => { this.setState({ profileDob: value});}

    profileEmail = (event) => { this.setState({ profileEmail: event.target.value});}



    clearAddress = (event) => { 
        this.setState({ addressmask : ''}); 
        this.setState({ address_full: ''}); 
    }


    businessCustomer = (business) => {     
        this.setState({ businessCustomer: business},()=>{
            let check = this.state.businessCustomer === true ? "BU" : null;
            let checked = this.state.businessCustomer === true ? "checked" : null;
            this.setState({companyDetails: check});
            this.setState({companyChecked: checked});
            if(check == null){
                this.setState({ companyName: null});
                this.setState({ tradingName: null});
                this.setState({ acn: null});
                this.setState({ abn: null});
            }
        });
    }

    companyName = (event) => { this.setState({ companyName: event.target.value});}
    tradingName = (event) => { this.setState({ tradingName: event.target.value});}
    acn = (event) => { this.setState({ acn: event.target.value});}
    abn = (event) => { this.setState({ abn: event.target.value});}




    mySubmitHandler = async (event) => { 
        event.preventDefault();  
        this.setState({loading:true});

        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            this.setState({loading:false});

            return;

        }
                
        const post_data =   {  
                                first_name : this.state.profileFName,
                                middle_name : this.state.profileMName,
                                surname : this.state.profileLName,
                                sal : this.state.profileTitle,
                                dob : this.state.profileDob,
                                email : this.state.profileEmail,
                                adress_full : this.state.address_full,
                                session_id : sessionStorage.getItem('activation_session_id'),
                                customer_type : this.state.companyDetails,
                                company_name : this.state.companyName,
                                trading_name : this.state.tradingName,
                                acn : this.state.acn,
                                abn : this.state.abn
                            };

                //====only for development======//

                if(app_environment === 'local')
                {
                    post_data.adress_full       = '1234B Terang-Framlingham Road, THE SISTERS VIC 3265';
                }
        
                //====only for development======//
        
        let headers     = await getApiHeaderGuest();
        let res         = await axios.post(`${ SERVER_URL }activation/profile-setup`,post_data, { headers }).then(response => {
            this.setState({loading:false});
            if(typeof(response.data.message) !== 'undefined')
            {
                // this.setErrorMessages(response.data.message,'success');
                this.props.nextStep();
            }
            else
            {
                //this.setErrorMessages("Profile Updated",'success');
                this.props.nextStep();
            }

        }).catch(error => {
            this.setState({loading:false});
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                this.setErrorMessages(error.response.data.message,'warning');
            }
            else
            {
                swal("Internal Server Error", "", 'warning');
            }
            
        });
    };


    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type).then(()=>{
            
            if(type === 'success')
            {
                this.props.nextStep();
            }

        });

    }




    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };


    handleKeypress = (e) => {
        e.preventDefault();
        return false
    }



    onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    var pattern = new RegExp(/^(([a-z])|([0-9])|@|\.|_|-)/i);
        if (!pattern.test(keyValue)) {
            event.preventDefault();
        }
    }




    render() {
        const { values } = this.props;

        return (
             <>
             {(this.state.loading === true) ? <PageLoader/> : null}
             <form className="kt-form kt-section__content kt-section__content--border common-layout" id="kt_form" method="post" onSubmit={this.mySubmitHandler}>
                <div className="kt-wizard-v2__content common-layout" data-ktwizard-type="step-content">
                                            <div className="kt-heading kt-heading--md">Setup Your Profile 
                                                

                                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger" style={{float:"right"}}>
                                                    I'm a business customer

                                                    <input type="checkbox" name="business_customer" id="business_customer" value={this.state.businessCustomer} defaultChecked={this.state.companyChecked}  onClick={e => {this.businessCustomer(e.target.checked);}}/>
                                                   
                                                    <span></span>
                                                </label>


                                            </div>
                                            <label><b>Your personal details must match your ID.</b></label>
                                            <div className="kt-separator kt-separator--height-xs"></div>
                                            <div className="kt-form__section kt-form__section--first">
                                                <div className="row">

                                                {this.state.companyDetails == 'BU' &&

                                                <>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Company Name:</label>
                                                            <input type="text" className="form-control" name="companyName" placeholder="Enter your company name" onChange={this.companyName} defaultValue={this.state.companyName} maxLength="60" required />
                                                            

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Trading Name:</label>
                                                            <input type="text" className="form-control" name="tradingName" placeholder="Enter your trading name" onChange={this.tradingName} defaultValue={this.state.tradingName} maxLength="60" />
                                                            

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>A C N:</label>
                                                            <input type="text" className="form-control" name="acn" placeholder="Enter A C N" onChange={this.acn} defaultValue={this.state.acn} maxLength="9" pattern="[0-9]{9}" title="Please enter valid A C N" required />
                                                            

                                                        </div>
                                                    </div>



                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>A B N:</label>
                                                            <input type="text" className="form-control" name="abn" placeholder="Enter A B N" onChange={this.abn} defaultValue={this.state.abn} maxLength="11" pattern="[0-9]{11}" title="Please enter valid A B N" required />
                                                            

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                    <p style={{padding: '5px 15px', background: '#fcf2f7', borderRadius: '5px', fontStyle: 'italic'}}>{this.state.businessText}</p>
                                                    </div>

                                                </>
                                                }













                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>First Name:</label>
                                                            <input type="text" className="form-control" name="fname" placeholder="Enter your first name" onChange={this.profileFName} defaultValue={this.state.profileFName} maxLength="40" />
                                                            {this.validator.message('First Name', this.state.profileFName, 'required', { className: 'text-danger' })}

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Middle Name:</label>
                                                            <input type="text" className="form-control" name="mname" placeholder="Enter your middle name"  onChange={this.profileMName} defaultValue={this.state.profileMName} maxLength="40" /> 
                                                            
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Last Name:</label>
                                                            <input type="text" className="form-control" name="lname" placeholder="Enter your last name"  onChange={this.profileLName} defaultValue={this.state.profileLName}  maxLength="40" />
                                                            {this.validator.message('Last Name', this.state.profileLName, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Title</label>
                                                            <select className="form-control kt_selectpicker" name="prof_title" id="prof_title" onChange={e => {this.profileTitle(e.target.value);}} value={this.state.profileTitle} >
                                                                <option value="">Select Title</option>
                                                                { this.state.salutations_list.map(salutation => <option value={salutation.salutation}>{salutation.salutation}</option>)}  
                                                            </select>
                                                           {this.validator.message('Title', this.state.profileTitle, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group customdate">
                                                            <label>Date of Birth:</label>
                                                             <div>
                                                            <DatePicker
                                                                onChange={this.profileDob}
                                                                className="form-control"
                                                                format="y-MM-dd"
                                                                value={this.state.profileDob}
                                                                minDate={this.state.minDob}
                                                                maxDate={this.state.maxDob}
                                                                onKeyPress={ this.handleKeypress}
                                                                onFocus={e => e.target.blur()}



                                                                
                                                            />
                                                            </div>
                                                            {this.validator.message('Date Of Birth', this.state.profileDob, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div> 

                                                   <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Email:</label>
                                                            <input type="text" className="form-control" name="email" id="email" placeholder={this.state.profileEmail} readOnly defaultValue={this.state.profileEmail}/>
                                                            {this.validator.message('Email Address', this.state.profileEmail, 'readonly', { className: 'text-danger' })}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">


                                                    {(app_environment !== 'local') ? 
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label id="address_label">Address:</label>

                                                            {this.state.addressmask=='' && 
                                                            <AddressFinder
                                                            addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                                                            inputClassName="form-control"
                                                            id="address"
                                                            placeholder="Enter unit/house/building number and street address"
                                                            onChange={this.setaddressFinder}
                                                            />
                                                            }

                                                            {this.state.addressmask=='1' && 
                                                            <div className="addressbox">
                                                            <i className="clearaddress" onClick={this.clearAddress}>x</i>
                                                            <input type="text" className="form-control" value={this.state.address_full} placeholder="Enter your Address" disabled />
                                    
                                                            </div>
                                                            }
                                                            {this.validator.message('Address', this.state.address_full, 'required', { className: 'text-danger' })}


                                                        </div>
                                                    </div>
                                                    : null}
                                                </div> 

                                               
                                            </div>
                                    

                    
                    <br/>
                    <div className="kt-form__actions">
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp;
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading}>Next Step</button>
                    </div>

                </div>      
            </form> 
            </>  
        );
    }
}

export default SetupYourProfile;
