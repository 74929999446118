import React, { Component } from "react";
import axios from 'axios';    
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions';
import { WidgetInput } from "react-addressfinder";
import PageLoader from './../Loader/loader';
import "react-addressfinder/dist/widget.css";

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;   
// const headers       = getApiHeader();


const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress, address) => {
           onChange(fullAddress,address);
        }}
      />
      )
}


export default class MyProfile extends Component {
    
     constructor(props) {
        super(props);
        
        this.state = { 
            firstName: '' ,
            customer_profile: [],
            customer_address: [],
            address_full:'',
            editemail:0,
            email:'',
            email_edit:'',
            otp:'',
            addressedit:0,
            showMe:true,
            verification_token:'',
            loading:false,
            otp_send_success:'',
            otp_send_failed:'',
            otp_error:null
        };

        this.validator = new SimpleReactValidator();

    }

 
    componentDidMount() {
        this.getUserStatus();
        this.getProfile();
        this.setState({loading:true});
    }


     // force logout --------------------------------------


    getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };


     handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    // this.props.history.push("/login");
                    window.location.href = "/login";
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------






    getProfile = async () => {
        let headers = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }profile`, { headers });
        let profile_data        = res.data.data.profile;
        let address_data        = res.data.data.address;
        console.log(res.data.data.profile);
        console.log(address_data);        
        this.setState({ customer_profile: profile_data });
        if(address_data != null)
        {
        this.setState({ address_full: address_data.address_full });
        }
        this.setState({ sal: profile_data.sal });
        this.setState({ firstName: profile_data.firstName });
        this.setState({ middleName: profile_data.middleName });
        this.setState({ surname: profile_data.surname });
        this.setState({ dob: profile_data.dob });
        this.setState({ gender: profile_data.gender });
        this.setState({ email: profile_data.email });
        this.setState({ mobile: profile_data.mobile });
        this.setState({ email_verified_at: profile_data.email_verified_at });
        this.setState({loading:false});
    };



    setaddressFinder= async (full_address,address) => {
        this.setState({ address_full: full_address });
        this.Canceladdress();
        this.mySubmitHandler();
    };




    EditEmail = (event) => {
        this.setState({ editemail: 1});
    }

    CancelEmail = (event) => {
        this.setState({ editemail: 0, otp_send_success: '', otp_error:null});
    }

    checkEmail = (event) => {
        this.setState({ email_edit: event.target.value});
    }

    checkotp = (event) => {
        this.setState({ otp: event.target.value});
    }


    verifyOTP =  async (event) => { 
        this.setState({loading:true, otp_error:null});
        let headers = await getApiHeader();
        const post_data =   { email: this.state.email_edit,
                              otp: this.state.otp,
                              session_id: this.state.verification_token,
                             };

        try{
            let response = await axios.post(`${ SERVER_URL }verifyOTPmail`,post_data, { headers });
            let data = response.data;  
            console.log(data);
            if(data.status==200){  
            this.setState({loading:false});
            
            this.setState({ otp_send_failed: '' });
            this.setState({ otp_send_success: '' });
            swal(data.message, "", "success");
            this.setState({ editemail: 0});
            this.setState({ email: this.state.email_edit});
            }else{
                this.setState({loading:false});
            swal(data.message, "", "warning");
            } 
        }
        catch(error)
        {
            this.setState({otp_error:error.response.data.message, loading:false});
        }
        
    }


    verifyEmail =  async (event) => { 

        if(this.validator.fieldValid('Email') === false) 
        {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }

        this.setState({loading:true});
        let headers = await getApiHeader();
        const post_data =   { email: this.state.email_edit };
        let response = await axios.post(`${ SERVER_URL }verifyemail`,post_data, { headers });
        let data = response.data;  
        console.log(data);
        if(data.status==200){  
            this.setState({loading:false});
            this.setState({ verification_token: data.data.otp_token});
            //swal(data.message, "", "success"); 
            this.setState({ otp_send_failed: '' }); 
            this.setState({ otp_send_success: data.message }); 
        }else{
            this.setState({loading:false});
            //swal(data.message, "", "warning");
            this.setState({ otp_send_failed: data.message });
            this.setState({ otp_send_success: '' });
        } 
        
    }



    Editaddress = (event) => {
        this.setState({ addressedit: 1});
    }

    Canceladdress = (event) => {
        this.setState({ addressedit: 0});
    }


    


    // for edit
    mySubmitHandler = async (event) => { 
        this.setState({loading:true});
        swal({ 
            text: "Are you sure you want to change the Profile Address?",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Cancel!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const post_data =   { 
                                      
                                      address_full: this.state.address_full,
                                    };
                console.log(post_data);
                let headers = await getApiHeader();
                let resp         = await axios.post(`${ SERVER_URL }profile_update`,post_data, { headers }).then(response => {
                                        this.setState({loading:false});
                                        if(typeof(response.data.message) !== 'undefined')
                                        {
                                        this.setErrorMessages(response.data.message,'success');
                                        }
                                        else
                                        {
                                        this.setErrorMessages("Address Updated",'success');
                                        }
                                        this.Canceladdress();
                                        this.getProfile();

                                    }).catch(error => {
                                        this.setState({loading:false});
                                        if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                                        {
                                            this.setErrorMessages(error.response.data.message,'warning');
                                        }
                                        else
                                        {
                                            swal("Internal Server Error", "", 'warning');
                                        }
                                        this.Canceladdress();
                                        this.getProfile();
                                        
                                    });
                
            } else {
                swal("Cancelled", "", "success");
                this.Canceladdress();
                this.getProfile();
            }
        });

    }



    setErrorMessages = (errorMessage,type)=>{
       let responseError = 'Internal server error';
        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {
                responseError = '';
                Object.keys(errorMessage).map(function(key, index) {
                    responseError+=errorMessage[key];
                });
            }
            else{
                responseError = errorMessage;
            }
        }
        swal(responseError, "", type);
    }
    // for edit


    render() {

       return (
    <>
    {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class" id="kt_content"> 
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">My Profile</h3>
                                    </div>
                                </div> 

                                <form className="kt-form kt-form--label-right" id="kt_profile_form" onSubmit={this.mySubmitHandler}>
                                    <input type="hidden" name="_token" value="ObupZ5m1zGiLOR7PsuEx5ioUiO7d83PmvaanZUVD"/>                        
                                    <div className="kt-portlet__body">
                                        <div className="kt-section kt-section--first">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    
                                                    <div className="col-lg-12 col-xl-12 myprofilebox">
                                                        <label className="col-xl-3 col-lg-3 col-form-label txt-left label_head"><span>Name </span></label>
                                                        <label className="col-xl-9 col-lg-9 col-form-label txt-left label_value"> : {this.state.sal} {this.state.firstName} {this.state.middleName} {this.state.surname}</label>   
                                                    </div>

                                                    <div className="col-lg-12 col-xl-12 myprofilebox">
                                                        <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Date of Birth </span></label>
                                                        <label className="col-xl-9 col-lg-9 col-form-label txt-left label_value"> : {this.state.dob}</label>
                                                    </div>

                                                    <div className="col-lg-12 col-xl-12 myprofilebox">
                                                        <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Email Address </span></label>
                                                        <label className="col-xl-7 col-lg-7 col-form-label txt-left label_value">
                                                        {this.state.editemail == 0 &&
                                                        <p> : {this.state.email}</p>
                                                        }

                                                        {this.state.editemail == 1 &&
                                                        <div className="input-group emailinputbox">
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">Enter New Email Address</label>
                                                            
                                                            <div className="col-lg-12 col-xl-12 input-group myprofileboxgroup">
                                                                <input type="email" className="form-control" required onChange={this.checkEmail} title="Enter Valid Email Address" value={this.state.email_edit}/>
                                                                
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-brand" id="verify_email" type="button" onClick={this.verifyEmail}>Get OTP
                                                                    </button>
                                                                </div>
                                                                
                                                            </div>
                                                            {this.validator.message('Email', this.state.email_edit, 'required|email', { className: 'text-danger' })}
                                                            <span  style={{color: 'green'}} role="alert">{this.state.otp_send_success}</span>
                                                            <span  style={{color: '#C11D6C'}} role="alert">{this.state.otp_send_failed}</span>

                                                            {/* <div className="col-lg-12 col-xl-12 input-group">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{fontSize: '12px'}}>Please check your email and enter the OTP</label>
                                                            </div> */}

                                                            {(this.state.otp_send_success !== '') &&
                                                            <div className="col-lg-12 col-xl-12 myprofileboxgroup" style={{marginTop: '5px'}}>
                                                                <input type="text" className="form-control" required  style={{marginBottom: '10px'}} onChange={this.checkotp} title="Enter Valid OTP"/>
                                                                {(this.state.otp_error !== null ) ? <><span style={{color:"red"}}>{this.state.otp_error}</span><br/></> : null} 
                                                                <button className="btn btn-brand" id="verify_email_btn" type="button" onClick={this.verifyOTP}>Verify</button>
                                                                <button className="btn btn-success" id="verify_email_cancel" type="button" onClick={this.CancelEmail}>Cancel</button>
                                                            </div>}

                                                        </div>
                                                        }

                                                        </label>
                                                        {/* <label className="col-xl-2 col-lg-2 col-form-label txt-right label_edit"><span onClick={this.EditEmail}>Edit</span></label> */}
                                                    </div>

                                                    <div className="col-lg-12 col-xl-12 myprofilebox">
                                                        <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Address </span></label>
                                                        <label className="col-xl-7 col-lg-7 col-form-label txt-left label_value">
                                                        {this.state.addressedit == 0 &&
                                                        <p> : {this.state.address_full}</p>
                                                        }

                                                        {this.state.addressedit == 1 &&
                                                         <div className="input-group" id="curr_addr_div">                                                            
                                                            <p>Enter Valid Address Here</p>
                                                            <AddressFinder
                                                            addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                                                            inputClassNam="address"
                                                            id="address"
                                                            onChange={this.setaddressFinder}
                                                            />
                                                            <button className="btn btn-success" type="button" onClick={()=>this.Canceladdress()}>Cancel</button>
                                                        </div>
                                                        }

                                                        </label>
                                                        {/* <label className="col-xl-2 col-lg-2 col-form-label txt-right label_edit"><span onClick={this.Editaddress}>Edit</span></label> */}
                                                    </div>
                                                

                                                   

                                                </div>

                                                  
                                            </div>
                                        </div>
                                    </div>
                                   
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </>
   )
}
}