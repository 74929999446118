import React, { Component } from 'react';
import { Link } from "react-router-dom"; 
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import {getApiHeaderGuest , getGuestRechargeAttemptDetails} from '../../../utils/CommonFunctions';
import swal from 'sweetalert';
import ErrorMsg from '../errorMsg';
import PageLoader from './../../Loader/loader';
import Moment from 'moment';
import DatePicker from 'react-date-picker';

import rechargeFriend from './../../../assets/img/image.png';
import { WidgetInput } from 'react-addressfinder';
const app_environment   = process.env.REACT_APP_ENV;
const isValidPhNo = /^[0-9]*$/;


const SERVER_URL    = process.env.REACT_APP_SERVER_URL; 

const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress, address) => {
           onChange(fullAddress,address);
        }}
      />
      )
}

export class SelectRechargeMethod extends Component {
    constructor(props) {

        super(props); 
        this.validator = new SimpleReactValidator();
        this.onOTPEditHandle = this.onOTPEditHandle.bind(this);
        this.handleResendOTP = this.handleResendOTP.bind(this);
        this.formatTime = this.formatTime.bind(this);


        this.state = {
            attempt_data:[],
            loading:false,
            rechargePayment:true,
            rechargeVoucher:false,
            mobileNumber:'',
            verified:0,
            session_id:null,
            voucher:false,
            VoucherNumber:null,
            voucherErr:null,
            myaccount_status:null,
            verifyOtp:false,
            otp:'',
            otp_failed:'',
            verification_token:'',
            disable_my_account_checking :false,
            guest_recharge_verification_status:'',
            otpVerifed:false,
            showCustomerDetails:false,
            customerDetails:'',
            addressmask:0,
            address_full:'',
            resendOtp:false,
            resendButton:null,
            timer: 180, 
            isResendDisabled: true,

            
        }
    }

    componentDidMount() { 
        
        this.getGuestRechargeAttemptDetails();  

        this.interval = setInterval(() => {
            if (this.state.timer > 0) {
                this.setState((prevState) => ({
                    timer: prevState.timer - 1,
                }));
            } else {
                this.setState({ isResendDisabled: false });
                clearInterval(this.interval);
            }
        }, 1000);
	}
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getGuestRechargeAttemptDetails = async () => {
        this.setState({loading:true});
		let attempt_data     = await getGuestRechargeAttemptDetails(); 

        if(attempt_data.recharge_guest_attempt != null && attempt_data.recharge_guest_attempt !== undefined)
        {
                await sessionStorage.setItem('guest_recharge_session_id', attempt_data.recharge_guest_attempt.session_id);
                this.setState({session_id:attempt_data.recharge_guest_attempt.session_id});
        } 
        this.setState({loading:false});
	};


    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type);

    }


    getMobilenumber = (event) => { 
        this.setState({ mobileNumber: event.target.value}); 
    }


    getVoucherNumber = (event) => {
        this.setState({ VoucherNumber: event.target.value}); 
    }


    resetAll = (event) => {
        this.setState({
            rechargePayment:true,
            rechargeVoucher:false,
            mobileNumber:'',
            verified:0,
            voucher:false,
            VoucherNumber:null,
            voucherErr:null
        });
    }



    
    onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    var pattern = new RegExp(/^([0-9])/i);
        if (!pattern.test(keyValue)) {
            event.preventDefault();
        }
    }


    onKeyCheck(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    var pattern = new RegExp(/^([a-zA-Z0-9])/i);
        if (!pattern.test(keyValue)) {
            event.preventDefault();
        }
    }







    mySubmitHandler = async (event) => {  
        event.preventDefault();  
        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            return;

        }




        this.setState({loading: true})
        const post_data =   {   mobile_number: this.state.mobileNumber,
            recharge_session :this.state.session_id
        }; 
        // let headers     = await getApiHeaderGuest();

        try
        {

        
            // let res         = await axios.post(`${ SERVER_URL }guestMobileNumberCheck`,post_data, { headers });
            let res         = await axios.post(`${ SERVER_URL }guestMobileNumberCheck`,post_data, {  });
            let data        = res.data;  

            if(data.data.session_id){ 
              
                    await sessionStorage.setItem('guest_recharge_session_id', data.data.session_id);
                    this.setState({loading: false});
                    this.setState({session_id: data.data.session_id});
                    await sessionStorage.setItem('guest_recharge_customer_id', data.data.customer_id);
                    await sessionStorage.setItem('guest_recharge_mobile_number', data.data.mobile_number);
                    this.setState({myaccount_status: data.data.myaccount_status});
                    this.setState({disable_my_account_checking:data.data.guest_recharge_disable_myaccount_checking})
                    this.setState({guest_recharge_verification_status:data.data.guest_recharge_verification_status})
            }

            if(data.status === 200){  
                    
                    this.setState({verified: 1});
                    if(this.state.disable_my_account_checking === true && this.state.guest_recharge_verification_status === 0){
                        if(this.state.myaccount_status !== null){
                            this.setState({verifyOtp:true})
                            this.setState({verification_token:data.data.verification_token,})
                        }
                    }
                    this.setState({loading:false , timer:180});
                    
               
            }else{
                swal(data.message, "", "warning");
            }

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                let optus = 0;
                 if(err.response && err.response.data && err.response.data.data && err.response.data.data.optus_check)
                {
                    if(err.response.data.data.optus_check == '1')
                    {
                        optus = 1;
                        window.setTimeout(function(){
                            window.location.replace("https://myaccount.telsim.com.au/");
                        }, 3000);

                        
                    }
                }



                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "waring"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        // swal(errorMessage,"error");

                        if(optus==1){
                            swal({
                                title: err.response.data.message,
                                icon: "info"});
                        }
                        else{
                            swal({
                                title: err.response.data.message,
                                icon: "error"});
                        }
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    };

    showMessage = (message) => {
        swal(message, "", 'warning');
    }

    validateForm = () => {
        const { otp } = this.state
        if (otp === '') {
            return "Please enter your OTP"
        }
    }


    onOTPEditHandle = (event) => {
        if(event.target.value.match(isValidPhNo)){
            this.setState({otp: event.target.value});
        }
    }

    onMobiVerifyBtnClick = async() => {
        
        const {mobileNumber, otp, verification_token,session_id, customerDetails,register_attempt_session_id, ExpiredCustomer} = this.state;
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading: true});
        const post_data =   { 
            mobile_number: mobileNumber, 
            otp: otp, 
            session_id: verification_token,
            register_attempt_session_id: session_id,
        };
        let res = await axios.post(`${ SERVER_URL }guest-recharge-otp-verification`, post_data, {}).then(response => {
            this.setState({
                 otpVerifed: true,
                 showCustomerDetails: true, otp_failed: '',
                 register_attempt_session_id: response.data.data.register_attempt_session_id,
                 customerDetails:response.data.data.customer,
            });
            swal(response.data.message,"","success")
            

        }).catch(error => {
            // console.log('onMobiVerifyBtnClick', error.response.data.message)
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
                this.setState({ otp_failed: error.response.data.message});
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
        this.setState({loading: false});
    }

    onAddessVerifyBtnClick = async () => {
        // event.preventDefault();
        this.setState({ loading: true });
        const { mobileNumber, register_attempt_session_id,session_id, address_full } = this.state;

        const post_data = {
            mobile_number: mobileNumber,
            address_full: this.state.address_full,
            session_id: session_id,
        }
       
        let headers = await getApiHeaderGuest();
        let res = await axios.post(`${SERVER_URL}guest-recharge-address-verification`, post_data, { headers }).then(response => {
            this.setState({ loading: false });
             console.log(response);

             this.setState({verified : 1 ,verifyOtp : false})
             swal(response.data.message,"","success")

        }).catch(error => {
            this.setState({ loading: false });
            if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {
                this.setErrorMessages(error.response.data.message, 'warning');
            }
            else {
                swal("Internal Server Error", "", 'warning');
            }

        });


    }

    setaddressFinder= async (full_address,address) => {
        this.setState({ address_full: full_address });
        this.setState({ addressmask: '1' });
    };

    clearAddress = (event) => { 
        this.setState({ addressmask : ''}); 
        this.setState({ address_full: ''}); 
    }


    

    handleResendOTP(e) {

        
        this.setState({
            timer: 180, // Reset timer to 3 minutes
            isResendDisabled: true,
        });

        
        this.mySubmitHandler(e)
        this.interval = setInterval(() => {
            if (this.state.timer > 0) {
                this.setState((prevState) => ({
                    timer: prevState.timer - 1,
                }));
            } else {
                this.setState({ isResendDisabled: false });
                clearInterval(this.interval);
            }
        }, 1000);
    }

    formatTime() {
        const minutes = Math.floor(this.state.timer / 60);
        const seconds = this.state.timer % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }




    toContinuePressHandle = async () => {
        try
        {   
            this.setState({voucherErr: null});
            this.setState({voucher: false});
            let rechargeType = '';
            let allow = false;
            console.log("this.state.rechargePayment",this.state.rechargePayment);
            if(this.state.rechargePayment == true){
                 rechargeType = 'Recharge';
                 allow = true;

                if (this.state.disable_my_account_checking === false) {
                    // if non myaccount user redirect to register Page
                    if (this.state.myaccount_status != null) {

                        swal(this.state.myaccount_status, "", "info");
                        window.setTimeout(function () {
                            window.location.replace("https://prepaid.telsim.com.au/Register");
                        }, 3000);

                        return;
                    }
                }

            }else if(this.state.rechargeVoucher == true){
                 rechargeType = 'RechargeWithVoucher';
                 if(this.state.VoucherNumber){
                    if(this.state.VoucherNumber == '' || this.state.VoucherNumber == null){

                        this.setState({voucherErr: 'The voucher number field is required.'});
                        return;

                    }else if(this.state.VoucherNumber.length < 5){

                        this.setState({voucherErr: 'Invalid voucher number.'});
                        return;

                    }else{
                        allow = true;
                    }
                 }else{
                        this.setState({voucherErr: 'The voucher number field is required.'});
                        return; 
                 }
            }


            if(allow==true){

                const post_data =   {   mobile_number: this.state.mobileNumber,
                recharge_session :this.state.session_id,
                recharge_type : rechargeType,
                voucher_number : this.state.VoucherNumber
                }; 
                // let headers     = await getApiHeaderGuest();


                this.setState({loading: true});

            
                // let res         = await axios.post(`${ SERVER_URL }guestMobileNumberCheck`,post_data, { headers });
                let res         = await axios.post(`${ SERVER_URL }guest-recharge-type-check`,post_data, {  });
                let data        = res.data;  

                if(data.data.session_id){ 
                        await sessionStorage.setItem('guest_recharge_session_id', data.data.session_id);
                        this.setState({loading: false});
                        this.setState({session_id: data.data.session_id});
                }

                if(data.status==200){  
                    if(data.data.recharge_type){
                        
                            this.props.nextStep();
                    
                    }else{
                        swal("Something went wrong", "", "warning");
                    }
                   
                }else{
                    swal(data.message, "", "warning");
                }
            }

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.data && err.response.data.data.session_out)
                {
                    if(err.response.data.data.session_out == '1'){
                        setTimeout(function(){
                                window.location.reload();
                        }, 3000);
                    }
                }




                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "waring"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        // swal(errorMessage,"error");
                        swal({
                            title: err.response.data.message,
                            icon: "error"});
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    };


    render() {
        const { values, handleChange } = this.props;
        const {rechargePayment, rechargeVoucher , otp ,otp_failed,customerDetails} = this.state;

        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
                <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" onSubmit={this.mySubmitHandler}>
                    <div className="row mb60" style={{ marginTop: 15 }}>
                        <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{ marginTop: 20 }}>
                            <div className="middleBox">
                                <div className="kt-portlet__head-label">
                                    <h1 className="kt-portlet__head-title" style={{ fontWeight: 'bold' }}>Recharge your Service</h1>
                                </div>

                                {this.state.verified == 0 &&

                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ width: '100%' }}>

                                        <div className="col-md-6" style={{ width: '100%' }}>
                                            <br />
                                            <div className="kt-portlet__head-label">

                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                    {<p style={{ fontWeight: 'bold' }}>Enter Your Telsim Mobile Number</p>}
                                                </label>
                                            </div>

                                            <input className="kt-option" type="text" placeholder="04XXXXXXXX" id="gestMobileNumber" name="mobile_number" style={{ width: '100%' }} maxlength="10" onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} onKeyPress={this.onKeyPress.bind(this)} onChange={this.getMobilenumber} value={this.state.mobileNumber} />
                                            {this.validator.message('Mobile Number', this.state.mobileNumber, 'required', { className: 'text-danger' })}
                                            <br />

                                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 15, width: '100%' }}>
                                                <div className="kt-form__actions">
                                                    <button
                                                        type="submit"
                                                        style={{ width: '100%', borderRadius: 5, height: 45 }}
                                                        className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                        id="wizard_next_btn"
                                                        data-ktwizard-type="action-next"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>

                                            <br />

                                        </div>
                                    </div>

                                }
                                {this.state.verified == 1 && this.state.verifyOtp === false &&
                                    <>


                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ width: '100%' }}>

                                            <div className="col-md-6" style={{ width: '100%' }}>
                                                <br />
                                                <div className="kt-portlet__head-label">

                                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                        {<p style={{ fontWeight: 'bold' }}>Telsim Mobile Number</p>}
                                                    </label>
                                                </div>


                                                <p className="tikmobile"><span className="tickgreen">✔</span> {this.state.mobileNumber} <span className="resetSpan" onClick={this.resetAll} >Reset</span></p>
                                                <br />


                                            </div>
                                        </div>



                                        <div className="kt-portlet__head-label">

                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                {<p style={{ fontWeight: 'bold' }}>How would you like to recharge?</p>}
                                            </label>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ width: '100%' }}>

                                            <div className="col-md-6" style={{ width: '100%' }}>
                                                <label className="kt-option" onClick={() => {
                                                    this.setState({ rechargePayment: true, rechargeVoucher: false })
                                                }}>
                                                    <span className="kt-option__control">
                                                        <span className="kt-radio kt-radio--check-bold">
                                                            <input type="radio" name="your_mobile_number" value="new" id="recharge_with_payment" checked={rechargePayment} />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Recharge with credit card
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6" style={{ width: '100%' }}>
                                                <label className="kt-option" onClick={() => {
                                                    this.setState({ rechargePayment: false, rechargeVoucher: true })
                                                }}>
                                                    <span className="kt-option__control">
                                                        <span className="kt-radio kt-radio--check-bold">
                                                            <input type="radio" name="your_mobile_number" value="new" checked={rechargeVoucher} id="recharge_with_voucher" />
                                                            <span></span>
                                                        </span>
                                                    </span>
                                                    <span className="kt-option__label">
                                                        <span className="kt-option__head">
                                                            <span className="kt-option__title">
                                                                Recharge with a voucher
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>


                                            {this.state.rechargeVoucher == true &&
                                                <div className="col-md-6" style={{ width: '100%' }}>
                                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                        {<p style={{ fontWeight: 'bold', marginBottom: 0 }}>Enter Voucher Number</p>}
                                                    </label>

                                                    <input className="kt-option" type="text" placeholder="Enter Recharge Voucher Number" id="Vouchernumber" name="voucher_number" style={{ width: '100%' }} maxlength="15" onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} onKeyPress={this.onKeyCheck.bind(this)} onChange={this.getVoucherNumber} value={this.state.VoucherNumber} />
                                                    <div id="voucherRequired" class="text-danger">{this.state.voucherErr}</div>

                                                </div>
                                            }

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 15, width: '100%' }}>
                                            <div className="kt-form__actions">
                                                <button
                                                    type="button"
                                                    style={{ width: '100%', borderRadius: 5, height: 45 }}
                                                    className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                    id="wizard_next_btn"
                                                    data-ktwizard-type="action-next"
                                                    onClick={this.toContinuePressHandle}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {this.state.verified == 1 && this.state.verifyOtp === true && this.state.otpVerifed === false &&
                                    <>

                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ width: '100%' }}>

                                            <div className="col-md-6" style={{ width: '100%' }}>
                                                <br />
                                                <div className="kt-portlet__head-label">

                                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                        {<p style={{ fontWeight: 'bold' }}>Enter Your Telsim Mobile Number</p>}
                                                    </label>
                                                </div>



                                                <input className="kt-option" type="text" placeholder="04XXXXXXXX" id="gestMobileNumber" name="mobile_number" style={{ width: '100%' }} maxlength="10" disabled value={this.state.mobileNumber} />
                                                <p className='text-success'>Your OTP has been sent successfully</p>
                                                <br />

                                            <div className="form-group">
                                                <label className="">Enter the OTP received in your Telsim Mobile Number</label>
                                                <input type="text" className="form-control" onChange={this.onOTPEditHandle} placeholder="" value={otp} required maxLength={6} max={6} />
                                                <span style={{ color:"#C11D6C" }} role="alert">{otp_failed}</span>
                                                {/* {this.validator.message('OTP', otp, 'required', { className: 'text-danger' })} */}


                                                <div className="resend-otp-section">
                                                    <button
                                                        onClick={this.handleResendOTP}
                                                        className="resend-otp-button"
                                                        disabled={this.state.isResendDisabled}
                                                    >
                                                        <i className="fas fa-sync-alt"></i> Resend OTP
                                                    </button>
                                                    <span className="timer">
                                                        {this.state.isResendDisabled && (
                                                            <>
                                                                <i className="fas fa-clock"></i> {`You can resend OTP in 3 minutes.`}
                                                            </>
                                                        )}

                                                    </span>
                                                </div>
                                            </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 15, width: '100%' }}>
                                                    <div className="kt-form__actions">
                                                        <a
                                                            // type="submit"
                                                            style={{ width: '100%', borderRadius: 5, height: 45 }}
                                                            className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-pill btn-sm"
                                                            // id="wizard_next_btn"
                                                            // data-ktwizard-type="action-next"
                                                            onClick={this.onMobiVerifyBtnClick}
                                                        >
                                                            Next
                                                        </a>
                                                    </div>
                                                </div>

                                                <br />


                                            </div>
                                        </div>

                                    </>
                                }
                                {this.state.verified === 1 && this.state.verifyOtp === true && this.state.otpVerifed === true && this.state.showCustomerDetails === true &&
                                    <>
                                        {/* <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}> */}
                                        <div className='row'>
                                            <div className="col-md-6" style={{ width: '100%' }}>
                                                {/* <label><b>Your personal details must match your ID.</b></label> */}
                                                <br />
                                                <div className="card mb-4">
                                                    <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <p className="mb-0">Mobile Number</p>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <p className="text-muted mb-0"> {this.state.mobileNumber}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <p className="mb-0">Full Name</p>
                                                        </div>
                                                        <div className="col-sm-9">
                                                            <p className="text-muted mb-0">{customerDetails.sal} {customerDetails.firstName} {customerDetails.middleName} {customerDetails.surename}</p>
                                                        </div>
                                                    </div>
                                                    
                                                        {/* <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Email</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">{customerDetails.email}</p>
                                                            </div>
                                                        </div> */}
                                                        {/* <hr />
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">DOB</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">{customerDetails.dob}</p>
                                                            </div>
                                                        </div> */}
                                                        {/* <hr />
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Gender</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">{customerDetails.gender}</p>
                                                            </div>
                                                        </div> */}
                                                        
                                                        {/* <hr /> */}

                                                        {/* <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Address</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">Bay Area, San Francisco, CA</p>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">


                                            {/* {(app_environment !== 'local') ?  */}
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label id="address_label">Enter Your Address:</label>

                                                    {this.state.addressmask == '' &&
                                                        <AddressFinder
                                                            addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                                                            inputClassName="form-control"
                                                            id="address"
                                                            placeholder="Enter unit/house/building number and street address"
                                                            onChange={this.setaddressFinder}
                                                        />
                                                    }

                                                    {this.state.addressmask == '1' &&
                                                        <div className="addressbox">
                                                            <i className="clearaddress" onClick={this.clearAddress}>x</i>
                                                            <input type="text" className="form-control" value={this.state.address_full} placeholder="Enter your Address" disabled />

                                                        </div>
                                                    }
                                                    {this.validator.message('Address', this.state.address_full, 'required', { className: 'text-danger' })}


                                                </div>
                                            </div>
                                            {/* : null} */}
                                        </div>
                                        {/* <br/> */}

                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginTop: 15, width: '100%' }}>
                                            <div className="kt-form__actions">
                                                <a
                                                    type="submit"
                                                    style={{ width: '100%', borderRadius: 5, height: 45 }}
                                                    className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                    id="wizard_next_btn"
                                                    data-ktwizard-type="action-next"
                                                    onClick={this.onAddessVerifyBtnClick}  
                                                >
                                                    Next
                                                </a>
                                            </div>
                                        </div>

                                        <br />
                                        {/* </div> */}
                                    </>
                                }

                            </div>

                        </div>
                        <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 hideView">
                            <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid" style={{ boxShadow: 'none' }}>
                                <div className="kt-portlet__body kt-portlet__body--fit">
                                    <img src={rechargeFriend} alt="image" />
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </>
                

        )
    }
}

export default SelectRechargeMethod
