import React,{ Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../../../components/Loader/LoadingIcon"
import axios from "axios";
import { getApiHeader } from "../../../../utils/CommonFunctions";
import DropIn from 'braintree-web-drop-in-react';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Payments extends Component{

     constructor(props){
        super(props)
         this.state={
            loading:false,
            checkTermAndContition:"",
            checkTermAndContitionAlert:false,
            agreeTermsError:false,
            apiMessage:"",
            apiStatusCode:"",
            apiCalled:false,
            clientToken:"",
            checkoutLoader:false,
            couponCode:"",
            errorMessage:"",
            successMessage:'',
            voucherCode:true,
            amount:""
            
            
         }
         this.instanceRef = React.createRef();
     }


   componentDidMount(){
     this.fetchToken()
   }


      fetchToken = async () => {
        try {
            this.setState({loading:true})
            let headers= await getApiHeader();
            
            let response 		= await axios.get(`${ SERVER_URL }payments/get-client-token`, { headers });

          const clientToken = response.data.data.clientToken;
          this.setState({  clientToken:clientToken,loading:false})
        } catch (error) {
          console.error('Error fetching client token:', error);
          this.setState({loading:false})
        }
      };

       handlecheckTermAndContition = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
        this.setState({
            checkTermAndContition:true,
            checkTermAndContitionAlert:false,
            agreeTermsError:false,
        })
        } else {
            this.setState({
                checkTermAndContition:false,
                checkTermAndContitionAlert:true,
                agreeTermsError:true,
            })
        }
      }


       handlesetCounponCode = (Typedvalue) => {
         this.setState({ couponCode:Typedvalue})
        if (Typedvalue.length === 0) {
         this. props.changeCouponCodeDetails(Typedvalue)
           this.setState({errorMessage:"",successMessage:""})
        }
      }

       
      applyCoupon = async(e) => {
         if(this.state.couponCode===""){
            return
         }

        let headers = await getApiHeader();
        this.props.changeCouponCodeDetails('');
        const postData = {
          nbn_voucher_code: this.state.couponCode,
          visp_qualification_id: this.props.vispID,
          payable_amount: this.props.payable_amount
        };
      
        axios.post(`${SERVER_URL}nbn/check_nbn_voucher_code_validity`, postData, {
          headers
        })
        .then(response => {
          if (response.status !== 200) {
            console.error('Error:', response);
            throw new Error(response.data.message);
          } else {
            this.setState({ voucherCode: response.data.data.voucher_name });
            const voucherDetails = {
              'voucher_name': response.data.data.voucher_name,
              'new_payable_amount': response.data.data.new_payable_amount,
              'plan_amount_after_discount': response.data.data.plan_amount_after_discount,
              'voucher_amount': response.data.data.voucher_amount,
              'nbn_voucher_discounted_amount': response.data.data.nbn_voucher_discounted_amount,
              "plan_gst_inclusive_sale_amount":response.data.data.plan_gst_inclusive_sale_amount,
              "nbn_credit_added":response.data.data.nbn_credit_added,
            };
      
            if (response.data.data.new_payable_amount< 1) {
              this.setState({ voucherCode: null });
              // Hide payment gateway
            } else {
              this.setState({ voucherCode: true });
            }
            this.props.changeCouponCodeDetails(voucherDetails);
            this.setState({ errorMessage:"", successMessage: response.data.message });
          }
        })
        .catch(error => {
          this.setState({ voucherCode: true, errorMessage: error?.response?.data?.message , successMessage: "" });
        });
      }
      
      




   buy = async (e) => {

    e.preventDefault();

    if (!this.state.checkTermAndContition) {
        this.setState({checkTermAndContition:false,checkTermAndContitionAlert:true,agreeTermsError:true})
      return; // Stop the function execution
    }

    try {
      let nonce = 'False';
      if(this.state.voucherCode &&this.props.conditionAmount>="1")
      {        
        const { nonce: generatedNonce } = await this.instanceRef.current.requestPaymentMethod(); 
        nonce = generatedNonce;
      
      }
      const requestData = {
        nonce: nonce,
        visp_qualification_id: this.props.vispID,
        nbn_voucher_code: this.state.couponCode,
        shipping_charge:this.props.shipping_charge,
        payable_amount: this.props.payable_amount
      };
      
        this.setState({checkoutLoader:true});
        let headers = await getApiHeader();
      const response = await axios.post(`${SERVER_URL}nbn/nbn_customer_purchase_payment`,requestData,{headers});

      if (response.status === 200) {
           this.setState({apiCalled:true, })
        const data = response.data
        this.setState({apiMessage:data.message , apiStatusCode:data.status})
        this.props.onChangeOrderRefId(data.data.ordRefID);
       this.props.onSuccessTap("ThankYouPage");
       await sessionStorage.setItem('is_nbn',1)
      } else {
         this.setState({apiCalled:true})
        throw new Error('Failed to make the API call');
      }
    } catch (error) {
      this.setState({apiCalled:true,apiStatusCode:false,apiMessage:error.response ? error.response.data.message : error.message})

    } finally {
        this.setState({checkoutLoader:false})
    }
  };


    render(){
        return(
            <div id="Payment" className="tabcontent">
            <div>
              {this.state.loading ? (
                <div>
                  <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={4000}
                  />
                </div>
              ) : (
                <div>
                  {!this.state.clientToken ? (
                    <div>
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="form-field col-lg-12">
                        <label className="label" htmlFor="mobile">
                          Special promo code or did a friend refer you?
                        </label>
                        <div className="input-group">
                          <input type="text" className="input form-control" onChange={e => this.handlesetCounponCode(e.target.value)} name="coupon" /> <br></br> <a className='btn btn-success coupon-btn' onClick={this.applyCoupon}>Apply</a>
                        </div>
                        {this.state.errorMessage && (
                          <p style={{ color:"#C11D6C", textAlign: 'left' }}>
                            <span style={{ marginRight: '5px' }} role="img" aria-label="Error">&#9888;</span>
                            {this.state.errorMessage}
                          </p>
                        )}
                        {this.state.successMessage && (
                          <p style={{ color: 'green', textAlign: 'left' }}>
                            <span role="img" aria-label="Success">&#10004;</span> {this.state.successMessage}
                          </p>
                        )}
                        <p style={{ textAlign: 'left', textDecoration: 'underline', marginTop: '10px', display: 'none' }}>Have an agent code?</p>
      
                      </div>

                      {this.state.voucherCode && this.props.conditionAmount >"1" &&  (
                      <DropIn
                        options={{
                          authorization: this.state.clientToken,
                        }}
                        onInstance={(instance) => (this.instanceRef.current = instance)}
                      />
                      )}
                      <br />
                      <p style={{ textAlign: 'left' }}>Terms and conditions:</p>
                      <label className="check-box" style={this.state.checkTermAndContitionAlert ? { color: '#777' } : {}}>
                        I have read and accept the <span style={this.state.checkTermAndContition ? { color: '#d30058' } : { color:"#C11D6C" }}><a target="_blank" href='https://www.telsim.com.au/website-terms-conditions/' style={this.state.checkTermAndContition ? { color: '#d30058', textDecoration: 'none' } : { color: '#d30058', textDecoration: 'none' }} >Terms & Conditions</a></span>, the Acceptable Use Policy,
                        the <span style={this.state.checkTermAndContition ? { color: '#d30058', textDecoration: 'none' } : { color: '#d30058', textDecoration: 'none' }} ><a target="_blank" href='https://www.telsim.com.au/website-privacy-policy/' style={this.state.checkTermAndContition ? { color: '#d30058', textDecoration: 'none' } : { color: '#d30058', textDecoration: 'none' }}>Privacy Policy</a></span> and <span style={this.state.checkTermAndContition ? { color: '#d30058', textDecoration: 'none' } : { color: '#d30058', textDecoration: 'none' }} ><a target="_blank" href='https://www.telsim.com.au/contact-us/' style={this.state.checkTermAndContition ? { color: '#d30058', textDecoration: 'none' } : { color: '#d30058', textDecoration: 'none' }}>Customer Service Guarantee Waiver</a></span> for my Telsim service.
                        <input type="checkbox" required="" id="agreeTerms" title="Please check and agree to the terms and conditions" value={this.state.checkTermAndContition} onChange={this.handlecheckTermAndContition} />
                        <span className="tick"></span>
                      </label>
                      <br />
                      <div className="row">
                        <div className="form-field col-lg-12">
                          <input className="payment-submit-btn" type="submit" value="CONTINUE" disabled={this.state.errorMessage} onClick={this.buy} />
                        </div>
                      </div>
                      <br></br>

                      {this.state.apiCalled && (
                        <div>
                          {this.state.apiStatus === 200 ? (
                            <div className="alert alert-success">
                              <strong>Successfully Submitted!</strong> {this.state.apiMessage}
                            </div>
                          ) : (
                            <div className="alert alert-danger">
                              <strong>Submission Failed!</strong> {this.state.apiMessage}
                            </div>
                          )}
                        </div>
                      )}

                      
                      {this.state.agreeTermsError === true && (
                        <>
                          <br />
                          <div className="alert alert-danger">
                            <strong>Please check and agree to the terms and conditions</strong>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <br></br>
            {this.state.checkoutLoader && (
              <div className="checkoutloader">
                <Loader
                  type="Puff"
                  color="#00BFFF"
                />
              </div>
            )}
          </div>
        )
    }

















}

 export default withRouter(Payments)