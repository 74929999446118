import React, { Component } from "react";  
import { withRouter } from 'react-router-dom'
import axios from 'axios';    
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions';
import ErrorMsg from '../public/errorMsg';
import Plan from '../public/plans/plan';
import swal from "@sweetalert/with-react";
import rechargeVoucher from './../../assets/img/telsimnewRechargeimg.png';
import PageLoader from './../Loader/loader';


const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  
const isValidPhNo = /^[0-9]*$/;

class RechargeWithVoucher extends Component {

    constructor(props)
    {
        super(props);
        let activeSection = '28-day_plan';
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) { 
            activeSection = this.props.location.state.activeSection;
        } 
        this.state = {
            plan_id: 0,
            selectedPlan: null,
            selected_number: null,
            customer_activations: [],
            activeSection: activeSection,
            isLoading: false,
            active_status: '',
            voucherNumber: '',
            showPlan: false,
            activation_id: 0,
            activation_status: '',
            customer_id: 0,
            toRecharge: "recharge-a-friend",
            voucher_id: 0
        }
    }

    componentDidMount() {
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) 
        { 
            this.setState({activeSection:this.props.location.state.activeSection});
        }
        this.getUserStatus();
        this.getActivations();
    }



     // force logout --------------------------------------
     getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };

    handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    this.props.history.push("/login");
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------





    getActivations = async () => {
        this.setState({isLoading: true})
        let headers = await getApiHeader();
        let res = await axios.get(`${SERVER_URL}user_activations`, { headers });
        let data = res.data.data.list;
        let selected_number = '';
        let activation_id = 0;
        let activation_status = '';
        var numberList = [];
        if(data.length > 0){
          //numberList = data.filter(item => item.activation_status == '1')
            selected_number = data[0].mobile_number;
            activation_id = data[0].activation_id;
            activation_status = data[0].activation_status;
          
        }else{ 
          selected_number = '';
          activation_id = 0;
        }
        this.setState({
          customer_activations: data, selected_number, activation_id, activation_status, isLoading: false
        }); 
    };

    handleChange =  (e) => {
        var active_number = e.target.value;
          var activation_id = e.target.selectedOptions[0].getAttribute('activation_id'); 
          var activation_status = e.target.selectedOptions[0].getAttribute('activation_status');
         
        if(e.target.selectedOptions[0].getAttribute('activation_status') == '1'){
          var active_number = e.target.value;
          var activation_id = e.target.selectedOptions[0].getAttribute('activation_id'); 
          var activation_status = e.target.selectedOptions[0].getAttribute('activation_status');
          this.setState({
              selected_number: active_number, activation_id, activation_status
          }); 
        } else if(e.target.selectedOptions[0].getAttribute('activation_status') == '9'){
          this.alertMessage('Mobile Number ' + e.target.value + ' has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
        } else if(e.target.selectedOptions[0].getAttribute('activation_status') == '5'){
          this.alertMessage('Please wait until your sim is activated to use your Telsim My Account. If you are facing any issues, please contact Telsim Customer Care.');
        } else {
          this.alertMessage('Mobile Number ' + e.target.value + ' is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.');
        }
        this.setState({
          selected_number: active_number, activation_id, activation_status
      }); 
        
    };

    alertMessage = (msg) => {
    swal(msg, "", "warning");
    }

    onMobileNoEditHandle = (voucherNumber) => {
        // if((voucherNumber.target.value.length <= 10) && voucherNumber.target.value.match(isValidPhNo)){
          this.setState({voucherNumber: voucherNumber.target.value});
        // }
    }

    validateForm = () => {
        const {voucherNumber, activation_status, selected_number} = this.state;
        if(activation_status == '9'){
            return 'Mobile Number ' + selected_number + ' has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.';
          } else if(activation_status == '5'){
            return 'Please wait until your sim is activated to use your Telsim My Account. If you are facing any issues, please contact Telsim Customer Care.';
          } else if(activation_status != '1'){
            return 'Mobile Number ' + selected_number + ' is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.';
          }

        if(voucherNumber == ''){
          return "Enter your voucher code";
        }
        return undefined
    }

    alertMessage = (msg) => {
        swal(msg, "", "warning");
    }

    toContinuePressHandle = async () => {
        const {voucherNumber, selected_number} = this.state
        const error = this.validateForm();
        if(error) return this.alertMessage(error);
        this.setState({ isLoading: true });

        const post_data = { 
            mobile_number: selected_number,
            voucher_code: voucherNumber
        };
        let headers     = await getApiHeader();
        await axios.post(`${SERVER_URL}recharge-with-voucher/verify-sap-voucher`, post_data, { headers })
        .then((response) => {
            console.log('verify-sap-voucher ==>', response)
            if(response !== undefined){
                if(response.data.status == 200) {
                    this.setState({ isLoading: false, selectedPlan: response.data.data.plan, plan_id: response.data.data.plan.plan_id, showPlan: true, voucher_id: response.data.data.voucher_id});
                }
            }
            this.setState({ isLoading: false});
            // this.setState({ isLoading: false, selectedPlan: response.data.plan, plan_id: response.data.plan.plan_id, showPlan: true});
            // console.log('verify-sap-voucher ==>', response.data.plan)
        })
        .catch((error) => {
            this.setState({ isLoading: false });
            console.log('error ==>', error.response)
            if (
              typeof error.response.data.message !== "undefined" &&
              typeof error.response.data !== "undefined"
            ) {
              this.alertMessage(error.response.data.message);
            } else {
              this.alertMessage("Internal Server Error");
            }
        });
    }

    onRechargeConfirm = () => {
        swal({

            buttons: {
            cancel: true,
            confirm: {text: 'Yes'},
            },
            text: "Would you like to proceed with this recharge for "+this.state.selected_number+ "?",
            dangerMode: true,
            })
            .then(proceed => {
                console.log('error ==>', proceed)
                if(proceed)
                {

                    // this.state({plan_after_confirmation: plan}, ()=>{

                        this.onRechargePressHandle();

                    // });

                }

        });
    }
    
    onRechargePressHandle = async () => {
        const {voucherNumber, selected_number, plan_id, voucher_id} = this.state
        const error = this.validateForm();
        if(error) return this.alertMessage(error);
        this.setState({ isLoading: true });
        let headers = await getApiHeader();
        var rSession = sessionStorage.getItem('rechargeSession');
        if(rSession == null) {
            rSession = new Date().getTime();
            sessionStorage.setItem('rechargeSession', rSession);
        }
        let params = {
            'payment_method':'Card', 
            'plan_id': plan_id, 
            'mobile_number': selected_number, 
            recharge_session : rSession,
            voucher_id: voucher_id
        }
        let response = null;
        try{
            response = await axios.post(`${ SERVER_URL }recharge/own-number`, params, { headers });
            this.setState({loading:false});
            swal({
                text: response.data.message,
                icon: "success"})
            .then(() => {
                this.props.history.push("/home");
            });

        }catch(err){
            //console.log('own-number error==>', err);
            this.setState({loading:false});
            if(err) {
                let errorMessage = '';
                if(err.response && err.response.data && err.response.data.message) {
                    //console.log('own-number error==>', err.response);
                    if(typeof(err.response.data.message) === 'object') {
                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
                    } else {
                        errorMessage = err.response.data.message;
                        if(err.response.data.status == 422){
                            swal({
                                title: 'Recharge Error',
                                text: errorMessage,
                                icon: "error"
                            }).then(()=> {
                                this.props.history.push("/MyPayments");
                            });
                        }else{
                            swal(errorMessage,"error");
                            swal({
                                title: errorMessage,
                                icon: "warning"
                            }).then(()=>{
                                this.props.history.push("/MyPayments");
                            });
                        }
                        
                    }
                } else {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
            }
        }
    }

    render() {
        const {selected_number, customer_activations, voucherNumber, showPlan, isLoading, activeSection, active_status, toRecharge, activation_id, customer_id, selectedPlan, plan_id} = this.state;

        let default_tab='28-day_plan';
        let extratab= window.sessionStorage.getItem("recharge");
        if(extratab=="extras_plan") {
            default_tab = "extras_plan";
            window.sessionStorage.setItem("recharge", "28-day_plan");
        }
        
        return (
            <>
                {isLoading === true && (<PageLoader/>)}
                <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor recharge" id="kt_content"> 
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                                <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__head">
                                            <div className="row" style={{width:"100%"}}>
                                                <div className="col-md-6">
                                                    <div className="kt-portlet__head-label" style={{marginLeft: -15}}>
                                                        <h3 className="kt-portlet__head-title">Recharge</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb60" style={{marginTop: 15}}>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{marginTop: 20}}>
                                                {/* <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid"> */}
                                                    <div className="kt-portlet__head-label">
                                                        <h1 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>Recharge with a voucher</h1>
                                                        {/* <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p style={{fontWeight: 'bold'}}>Recharge yourself, or for someone else, on your phone or desktop.</p>}
                                                        </label> */}
                                                    </div>

                                                    
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                        Please select your Telsim Mobile Number
                                                        </label>
                                                        <select className="form-select custom-select kt_selectpicker"
                                                        style={{height: 45}} 
                                                        defaultValue={selected_number} 
                                                        onChange={this.handleChange} 
                                                        name="activation">
                                                            { customer_activations.map(person => <option className="m--bg-success kt-font-inverse-success" activation_id={person.activation_id} activation_status={person.activation_status} key={person.activation_id} value={person.mobile_number}>{person.mobile_number}</option>)} 
                                                        </select>
                                                    </div>
                                                
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>Enter your voucher code</p>}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            style={{marginTop: -10, height: 45}}
                                                            type='text'
                                                            // maxLength={10}
                                                            // max={10}
                                                            value={voucherNumber}
                                                            onChange={this.onMobileNoEditHandle}
                                                        />
                                                    </div>
                                                    {showPlan == true && (<div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <Plan 
                                                        plan_reference_number={voucherNumber} 
                                                        referenceNumberApplied={true} 
                                                        plan={selectedPlan} 
                                                        selectedPlan={plan_id}/>
                                                    </div>)}
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{marginTop: 15, width: '100%'}}>
                                                        <div className="kt-form__actions">
                                                            <button
                                                            type="submit"
                                                            style={{width: '100%', borderRadius: 5, height: 45}}
                                                            className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                            id="wizard_next_btn"
                                                            data-ktwizard-type="action-next"
                                                            onClick={showPlan == true ? this.onRechargeConfirm : this.toContinuePressHandle}
                                                            >
                                                            {showPlan == true ? 'Recharge' : 'Continue'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 hideView">
                                                <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid">
                                                    <div className="kt-portlet__body kt-portlet__body--fit" style={{alignItems: 'center'}}>
                                                        <img src={rechargeVoucher} alt="image" style={{height: 150, width: 150, marginTop: 50}}/>	
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </>
        )
    }
}


export default withRouter(RechargeWithVoucher);