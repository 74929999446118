import React, { Component } from 'react';
import DropIn from "braintree-web-drop-in-react";
import axios from 'axios';    
import swal from 'sweetalert';
import DatePicker from 'react-date-picker';
import {getApiHeaderGuest ,getApiHeader, removeUserSession, getActivationAttemptData} from './../../../../utils/CommonFunctions';
import SimpleReactValidator from 'simple-react-validator';
import ErrorMsg from './../../../public/errorMsg';
import PageLoader from './../../../Loader/loader';
import Loader from "react-loader-spinner";

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;

export class PaymentMethod extends Component {

    constructor(props) {

    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
        CardName: '',
        cardNumber:'',
        cardExpiry:'',
        cardCvv:'',
        termsCheckerr:false,
        plan_id: 1,
        cardExp:'',
        termsCheck:false,
        isCredit:0,
        autoRechargeStatus:false,
        autoRechargeStatusCheck:false,
        autoRecharge:true,
        autoRechargeCheck:"checked",
        paymentDetails:[],
        totalAmount:0,
        loading:true,
        clientToken:null,
        takefull_amount:null,
        port_in_check_message:null,
        payable_amount:null,
        paymsg:null,
        special_sim:0
        }
    }


    CardName = (event) => { this.setState({ CardName: event.target.value});}
    cardNumber = (event) => { this.setState({ cardNumber: event.target.value});}
    cardExpiry = (expiry) => 
    { 
        let year  = expiry.getFullYear();
        let month = expiry.getMonth() + 1;
        let cardDate  = month+'/'+year;

        this.setState({ cardExpiry: expiry, cardExp: cardDate});
        
    }
    cardCvv = (event) => { this.setState({ cardCvv: event.target.value});}
    autoRecharge = (renew) => { 

        
        this.setState({ autoRecharge: renew},()=>{


            let check = this.state.autoRecharge === true ? "checked" : null;

            this.setState({autoRechargeCheck: check});

        });
    

    }
    termsCheck = (check) => { this.setState({ termsCheck: check, termsCheckerr:false});}

    autoRechargeStatusCheck = (check) => {this.setState({autoRechargeStatusCheck: check,autoRechargeStatus: check ? true : false,});}
    

    componentDidMount = async ()=>{

        this.getPaymentClientToken();

        try 
        {

            let headers 	= await getApiHeader();

            let inputData   = {session_id: sessionStorage.getItem('activation_session_id')};
            
            let res 		= await axios.post(`${ SERVER_URL }activationv2/payments/get-selected-items`, inputData, { headers });

            let response    = res.data.data;

            this.setState({paymentDetails: response.plan, totalAmount: response.total_amount, loading:false, takefull_amount:response.takefull_amount, port_in_check_message:response.port_in_check_message, payable_amount:response.payable_amount, special_sim:response.special_sim,isCredit:response.is_credit});

            if(response.pay_msg) { this.setState({paymsg:response.pay_msg}); }
            if(response.special_sim === 1){
                this.setState({autoRechargeStatus:true})
            }

            if (response.special_sim === 0 && response.is_credit === 0) {
                this.setState({ autoRechargeStatusCheck: true, autoRechargeStatus: true });
            }

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }


    handleSubmitButtonClick = (event) => {
        // this.setState({ loading: true });
        if (this.state.special_sim === 0) {
            if (this.state.isCredit === 1 && !this.state.autoRechargeStatusCheck) {
                this.mySubmitActivationHandler(event);
            } else {
                this.mySubmitHandler(event);
            }
        } else {
            this.mySubmitActivationHandler(event);
            // Handle the condition accordingly for special_sim !== 0
        }
    }

    

    mySubmitHandler = async (event) => { 

        event.preventDefault();  

        if (this.validator.allValid() == false) 
        {

            this.setState({loading:false});

            this.validator.showMessages();
            this.forceUpdate();
            return;
        } 

        if(this.state.termsCheck === true )
        {
            
            const { nonce } = await this.instance.requestPaymentMethod();
            
            this.setState({loading:true});

                const post_data =   {  
                                        // card_holder_name : this.state.CardName,
                                        // card_number      : this.state.cardNumber,
                                        // card_expiry      : this.state.cardExp,
                                        // cvv              : this.state.cardCvv,
                                        is_credit          : this.state.isCredit,
                                        automate_recharge : this.state.autoRechargeStatus ? 1 : 0,
                                        payable_amount  : this.state.payable_amount,
                                        takefull_amount  : this.state.takefull_amount,
                                        termsCheck       : this.state.termsCheck,
                                        auto_renew       : this.state.autoRecharge,
                                        // plan_id          : this.state.plan_id,
                                        session_id       : sessionStorage.getItem('activation_session_id'),
                                        nonce            : nonce
                                    };

             
                let headers     = await getApiHeader();

                let res         = await axios.post(`${ SERVER_URL }activationv2/payments/submit-payment`,post_data, { headers }).then(response => {

                    this.setState({loading:false});
                    
                   

                    this.props.nextStep();

                }).catch(error => {

                    this.setState({loading:false});

                    if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                    {
                        this.setErrorMessages(error.response.data.message,'warning');
                    }
                    else
                    {
                        swal("Internal Server Error", "", 'warning');
                    }
                    
                });

        }
        else
        {
         this.setState({ termsCheckerr: true, loading:false});
        }
    };

    getPaymentClientToken= async ()=>{

        try 
        {

            let headers 	= await getApiHeaderGuest();
            
            let res 		= await axios.get(`${ SERVER_URL }payments/get-client-token`, { headers });

            let response    = res.data.data;

            this.setState({clientToken:response.clientToken});

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }

    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type);

    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };






     mySubmitActivationHandler = async (event) => { 

        event.preventDefault();  

        if (this.validator.allValid() == false) 
        {

            this.setState({loading:false});

            this.validator.showMessages();
            this.forceUpdate();
            return;
        } 

        if(this.state.termsCheck === true)
        {


            this.setState({loading:true});

                const post_data =   {  
                                        termsCheck       : this.state.termsCheck,
                                        session_id       : sessionStorage.getItem('activation_session_id')
                                    };


                let headers     = await getApiHeader();

                let res         = await axios.post(`${ SERVER_URL }activationv2/payments/special-activation`,post_data, { headers }).then(response => {

                    this.setState({loading:false});

                    this.props.nextStep();

                }).catch(error => {

                    this.setState({loading:false});

                    if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                    {
                        this.setErrorMessages(error.response.data.message,'warning');
                    }
                    else
                    {
                        swal("Internal Server Error", "", 'warning');
                    }
                    
                });

        }
        else
        {
         this.setState({ termsCheckerr: true, loading:false});
        }
    };
    






    render() {
        const { values } = this.props;

        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
             <form className="kt-form kt-section__content kt-section__content--border common-layout" id="kt_form">
                                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">

                                            <p style={{color: "#000",fontSize:"16px", marginBottom:0}}>{(this.state.paymsg)}</p>

                                            <div className="kt-form__section kt-form__section--first" id="card_section">
                                                
                                                {/* <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Card Holder Name:</label>
                                                            <input type="text" className="form-control" name="card_name" placeholder="Enter Card Holder Name"  pattern='[A-Za-z\-\s]*' onChange={this.CardName} />
                                                            {this.validator.message('Card Holder Name', this.state.CardName, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Card Number:</label>
                                                            <input type="text" className="form-control" name="card_number" placeholder="Enter Card Number"  onChange={this.cardNumber} />
                                                            {this.validator.message('Card Number', this.state.cardNumber, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Expiry:</label>
                                                            <div>
                                                            <DatePicker
                                                                onChange={this.cardExpiry}
                                                                value={this.state.cardExpiry}
                                                                className="form-control customdate1"
                                                                format="MM-y"
                                                            
                                                            />
                                                            </div>
                                                            {this.validator.message('Expiry', this.state.cardExpiry, 'required', { className: 'text-danger' })}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>CVV:</label>
                                                            <input type="text" className="form-control " id="card_cvv" name="card_cvv" placeholder="Enter CVV" onChange={this.cardCvv} />
                                                            {this.validator.message('CVV', this.state.cardCvv, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* && this.state.autoRechargeStatusCheck === true */}
                                                {(this.state.clientToken !== null && this.state.special_sim === 0  && this.state.autoRechargeStatusCheck === true  ) ? 
                                                <>
                                                <DropIn options={{ authorization: this.state.clientToken, card:{ cardholderName: {'required':true}, 
                                                          overrides: {
                                                            styles: {
                                                              input: {
                                                                width:'100%'
                                                              }
                                                            }
                                                          }
                                            
                                            }}}
                                                onInstance={(instance) => (this.instance = instance)}
                                                />
                                                </>
                                                
                                                : 
                                                <div style={{width:"100%"}}>
                                                {this.state.special_sim === 0  && this.state.autoRechargeStatusCheck === true ? 
                                                  <p style={{textAlign:"center", fontSize:"18px", color:"red", fontWeight:"bold"}}>Loading...</p>
                                                  : null }
                                                </div>
                                            }

                                                <div className="row">
                                                
                                                    {/* <div className="col-lg-6">
                                                        <div className="form-group  row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <label className="kt-option" style={{padding: '10px'}}>
                                                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                        <input type="checkbox" name="agree_terms" id="agree_terms" value={this.state.autoRecharge} defaultChecked={this.state.autoRecharge}  onChange={e => {this.autoRecharge(e.target.checked);}}/>
                                                                        <span></span>
                                                                    </label>
                                                                    <span className="kt-option__label">
                                                                        <span className="kt-option__head">
                                                                            <span className="kt-option__title">
                                                                                Enable Auto Recharge
                                                                            </span>

                                                                        </span>
                                                                    </span>
                                                                </label>

                                                              
                                                                
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    
                                                </div>

                                                <div className="kt-invoice-1">
                                                    <div className="kt-invoice__body kt-padding-10" id="payment_cart">   
                                                        <div className="kt-invoice__container" style={{width: "80%", margin: "0 auto"}}>
                                                            <div className="table-responsive">

                                                                
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>DESCRIPTION</th>
                                                                            
                                                                            <th>AMOUNT DUE</th>
                                                                            
                                                                        </tr>
                                                                    </thead>
                                                                        <tbody>  
                                                                            {/* <tr>
                                                                                <td>60 GB Data Plan - For 28 Days.</td>
                                                                                <td>$28.00</td>
                                                                            </tr> */}

                                                                            {this.state.paymentDetails.map((data)=>{

                                                                                return <tr><td>{data.discription}</td><td>${data.amount}</td></tr>

                                                                            })}

                                                                        </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="kt-invoice__footer kt-padding-10">
                                                            <div className="kt-invoice__container" style={{width: "80%", margin: "0 auto"}}>
                                                                <div className="kt-invoice__bank">
                                                                </div>
                                                                <div className="kt-invoice__total pull-right">

                                                                    <span className="kt-invoice__title">TOTAL AMOUNT</span>

                                                                    {this.state.special_sim === 0 && this.state.isCredit === 0 ? (
                                                                        <span className="kt-invoice__price">${this.state.totalAmount}</span>
                                                                    ) : (
                                                                        <span className="kt-invoice__price">$0.00</span>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>											                
                                                </div>

                                                <div className="row">
                                                    {(this.state.special_sim === 0 &&  this.state.autoRechargeStatus === true) ? 
                                                    <div className="col-lg-12">
                                                        <label>Your card will be charged 1 AUD for card preauthorization. This pending transaction will be voided by your bank automatically within 7-14 business days. This payment method will be used for all future payments for this account. Payment method can be updated anytime in Telsim My Account.</label>
                                                    </div> : null }
                                                    <div className="col-lg-12">
                                                        <div className="form-group  row">

                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                {this.state.isCredit === 1 && this.state.special_sim === 0 && (
                                                                    <label className="kt-option">
                                                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                            <input type="checkbox" name="auto_recharge_status" id="auto_recharge_status" value="1" onChange={e => { this.autoRechargeStatusCheck(e.target.checked); }} />
                                                                            <span></span>
                                                                        </label>
                                                                        <span className="kt-option__label">
                                                                            <span className="kt-option__head">
                                                                                <span className="kt-option__title">
                                                                                    I want to set up automatic recharges
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                )}
                                                                {/* 
                                                                {this.state.termsCheckerr == true &&
                                                                <span className="" role="alert"><span id="authorized_error" style={{color:"#C11D6C",fontWeight: '400',paddingTop:'10px',display:'block'}}><i className="fa fa-exclamation-triangle"></i> Please confirm checkbox</span></span>
                                                                } */}
                                                                
                                                            </div>

                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <label className="kt-option">
                                                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                        <input type="checkbox" name="agree_terms" id="agree_terms" value="1"  onChange={e => {this.termsCheck(e.target.checked);}} />
                                                                        <span></span>
                                                                    </label>
                                                                    <span className="kt-option__label">
                                                                        <span className="kt-option__head">
                                                                            <span className="kt-option__title">
                                                                                I agree to Telsim's <a href="https://telsim.com.au/terms-and-conditions" target="_blank">terms & conditions</a> and<a href="https://telsim.com.au/website-privacy-policy" target="_blank"> policies.</a>
                                                                            </span>

                                                                        </span>
                                                                    </span>
                                                                </label>

                                                                {this.state.termsCheckerr == true &&
                                                                <span className="" role="alert"><span id="authorized_error" style={{color:"#C11D6C",fontWeight: '400',paddingTop:'10px',display:'block'}}><i className="fa fa-exclamation-triangle"></i> Please confirm checkbox</span></span>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                            <br/>
                                            <div className="kt-form__actions">
                                            <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp;
                                            

                                            {(this.state.special_sim === 0) ? 

                                            <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading} onClick={(event)=>{
                                                    // this.setState({loading:true});
                                                    this.handleSubmitButtonClick(event);
                                                }
                                            }>Pay & Proceed</button>

                                            : 
                                            
                                            <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading} onClick={(event)=>{
                                                    // this.setState({loading:true});
                                                    this.handleSubmitButtonClick(event);
                                                }
                                            }>Proceed</button>

                                            }

                                            
                                            </div>

                </div>      
            </form> 
            </>  
        );
    }
}

export default PaymentMethod;
